import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components
import StageTitle from "./StageTitle";
import Button from "../../UI/Button/Button";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import Input from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";

//constants
import ASSESSMENTS from "../../../constants/applicationAssessments";
import API from "../../../app/api";
import AuthService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

const StageFourAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const allowedEditorRoles = ["project manager"];
    const allowedViewerRoles = [
        "project manager",
        "project director",
        "school director",
        "school director deputy",
        "school teacher",
        "school psychologist",
        "school manager",
    ];

    const points = applicationAssessment?.stageFourPoints;
    const assessment = applicationAssessment?.stageFourAssessment;

    const [stageFourPoints, setStageFourPoints] = useState(Number.isInteger(points) ? points : "");
    const [stageFourAssessment, setStageFourAssessment] = useState(assessment || "");

    const stageFourPointsHandler = (value) => {
        const onlyNumbersRegExp = new RegExp("^[0-9]+$");

        if (onlyNumbersRegExp.test(value)) {
            setStageFourPoints(value);
        } else if (!value) {
            setStageFourPoints("");
        }
    };

    const stageFourAssessmentHandler = (value) => {
        setStageFourAssessment(value);
    };

    // const updateStageFour = () => {
    //     axios
    //         .post(API.assessment.updateStageFourAssessment, { id, stageFourPoints, stageFourAssessment }, { headers: authHeader() })
    //         .then(() => {
    //             window.location.reload();
    //         })
    //         .catch((error) => {
    //             if (error.response.status && [401, 403].includes(error.response.status)) {
    //                 AuthService.logout();
    //                 navigate("/signin");
    //                 window.location.reload();
    //             } else {
    //                 navigate("/error", { state: { error: error.response.data.message } });
    //             }
    //         });
    // };

    if (
        applicationAssessment?.stageThreeTeacherAssessment === "рекомендовано" &&
        applicationAssessment?.stageThreePsychologistAssessment === "рекомендовано"
    ) {
        return (
            <div className="stage">
                <StageTitle title="Етап 4. Співбесіда" />

                {allowedViewerRoles.includes(userRole) &&
                    (Number.isInteger(points) && assessment ? (
                        <div className="stage-rated" data-assessment={assessment}>
                            <p className="stage-rated-points">{points}</p>
                            <p className="stage-rated-assessment">{assessment}</p>
                        </div>
                    ) : (
                        <div className="stage-not-rated">
                            <p>Не оцінено</p>
                        </div>
                    ))}

                {allowedEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <Input
                            label="Кількість балів за співбесіду"
                            value={stageFourPoints}
                            handler={stageFourPointsHandler}
                            disabled={true}
                        />
                        <Select
                            label="Фінальна оцінка"
                            options={ASSESSMENTS}
                            value={stageFourAssessment}
                            handler={stageFourAssessmentHandler}
                            disabled={true}
                        />

                        {/* <ButtonGroup>
                            <Button content='Зберегти' handler={updateStageFour} disabled={!(stageFourPoints && stageFourAssessment)} />
                        </ButtonGroup> */}
                    </div>
                )}

                {!allowedViewerRoles.includes(userRole) && !allowedEditorRoles.includes(userRole) && (
                    <p className="stage-note">У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}
            </div>
        );
    }

    return (
        <div className="stage">
            <StageTitle title="Етап 4. Співбесіда" />
            <p className="stage-note">Анкета не отримала рекомендації на попередньому етапі.</p>
        </div>
    );
};

export default StageFourAssessment;
