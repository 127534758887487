import SERVER_URL from "../constants/server-url";

const API = {
    users: {
        readOne: SERVER_URL + "/api/user/read",
        readAll: SERVER_URL + "/api/user/read-all",
        updatePassword: SERVER_URL + "/api/user/update/password",
        updateUserProjectRole: SERVER_URL + "/api/user/update/project-role",
    },

    projectRoles: {
        readAll: SERVER_URL + "/api/project-role/read-all",
    },

    application2024: {
        create: SERVER_URL + "/api/2024/application/create",
        readAll: SERVER_URL + "/api/2024/application/read/all",
        readOneById: SERVER_URL + "/api/2024/application/read/id",
        checkOneByEmail: SERVER_URL + "/api/2024/application/check/email",
        exportApplications: SERVER_URL + "/api/2024/application/export",
        deleteApplicationById: SERVER_URL + "/api/2024/application/delete/id",
    },

    assessment2024: {
        readAssessmentStatistic: SERVER_URL + "/api/2024/assessment/read/statistic",
        updateStageOneAssessment: SERVER_URL + "/api/2024/assessment/update/stage-one/id",
        updateStageTwoAssessment: SERVER_URL + "/api/2024/assessment/update/stage-two/id",
        updateStageThreeTeacherAssessment: SERVER_URL + "/api/2024/assessment/update/teacher-stage-three/id",
        updateStageThreePsychologistAssessment: SERVER_URL + "/api/2024/assessment/update/psychologist-stage-three/id",
        updateStageFourAssessment: SERVER_URL + "/api/2024/assessment/update/stage-four/id",
    },

    application2025: {
        create: SERVER_URL + "/api/2025/application/create",
        readAll: SERVER_URL + "/api/2025/application/read/all",
        readOneById: SERVER_URL + "/api/2025/application/read/id",
        checkOneByEmail: SERVER_URL + "/api/2025/application/check/email",
        exportApplications: SERVER_URL + "/api/2025/application/export",
        deleteApplicationById: SERVER_URL + "/api/2025/application/delete/id",
        updateCodeById: SERVER_URL + "/api/2025/application/update/one/code/id",
    },

    assessment2025: {
        readAssessmentStatistic: SERVER_URL + "/api/2025/assessment/read/statistic",
        updateStageOneAssessment: SERVER_URL + "/api/2025/assessment/update/stage-one/id",

        updateStageTwoMath: SERVER_URL + "/api/2025/assessment/update/stage-two/math/id",
        updateStageTwoUkrainian: SERVER_URL + "/api/2025/assessment/update/stage-two/ukrainian/id",
        updateStageTwoEnglish: SERVER_URL + "/api/2025/assessment/update/stage-two/english/id",
        updateStageTwoNature: SERVER_URL + "/api/2025/assessment/update/stage-two/nature/id",

        updateStageTwoPsychologistAssessment: SERVER_URL + "/api/2025/assessment/update/stage-two/psychologist/id",

        updateStageThreeAssessment: SERVER_URL + "/api/2025/assessment/update/stage-three/id",
    },
};

export default API;
