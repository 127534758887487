import ReactDatePicker, { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk";

//styles
import "./style.scss";
import "react-datepicker/dist/react-datepicker.css";

export default function InputDate({ label, value, handler, required }) {
    registerLocale("uk", uk);

    return (
        <div className="custom-date-input">
            <div className="custom-date-input-label">
                {label}
                {required && <span className="custom-date-input-label-required"> *</span>}
            </div>
            <ReactDatePicker
                locale="uk"
                dateFormat="dd.MM.yyyy"
                selected={value}
                onChange={(value) => handler(value)}
                popperPlacement="bottom-start"
            />
        </div>
    );
}
