export const PROJECT_ROLE_TITLES = [
    "Менеджер проєкту",
    "Директор",
    "Заступник директора",
    "Вчитель Математики",
    "Вчитель Української мови",
    "Вчитель Англійської мови",
    "Вчитель Природничих наук",
    "Психолог",
];

export const PROJECT_ROLE_NAME_BY_TITLES = {
    "Менеджер проєкту": "project manager",
    Директор: "director",
    "Заступник директора": "director deputy",
    "Вчитель Математики": "math teacher",
    "Вчитель Української мови": "ukrainian teacher",
    "Вчитель Англійської мови": "english teacher",
    "Вчитель Природничих наук": "nature teacher",
    Психолог: "psychologist",
};

export const PROJECT_ROLE_TITLE_BY_NAMES = {
    "project manager": "Менеджер проєкту",
    director: "Директор",
    "director deputy": "Заступник директора",
    "math teacher": "Вчитель Математики",
    "ukrainian teacher": "Вчитель Української мови",
    "english teacher": "Вчитель Англійської мови",
    "nature teacher": "Вчитель Природничих наук",
    psychologist: "Психолог",
};
