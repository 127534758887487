//styles
import "./style.scss";

//images
import mainLogo from "assets/img/logos/main-logo-grey.png";
import osvitoriaLogo from "assets/img/logos/osvitoria-logo.svg";
import facebookIcon from "assets/icons/social/facebook-icon.svg";
import youtubeIcon from "assets/icons/social/youtube-icon.svg";

//constans
const FACEBOOK_LINK = "https://www.facebook.com/novoshkola";
const YOUTUBE_LINK = "https://www.youtube.com/user/novoshkola";
const NOVOSHKOLA_URL = "https://novoshkola.com.ua/";
const OSVITORIA_URL = "https://osvitoria.org/";

export default function Footer() {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer-divider'>
                    <div className='footer-logo'>
                        <a href={NOVOSHKOLA_URL} target='_blank' rel='noreferrer'>
                            <img src={mainLogo} alt='main logo' />
                        </a>
                    </div>
                    <div className='footer-info'>
                        <div className='footer-info-social'>
                            <div>
                                <a href={FACEBOOK_LINK} target='_blank' rel='noreferrer'>
                                    <img src={facebookIcon} alt='social-icon' />
                                </a>
                            </div>
                            <div>
                                <a href={YOUTUBE_LINK} target='_blank' rel='noreferrer'>
                                    <img src={youtubeIcon} alt='social-icon' />
                                </a>
                            </div>
                        </div>

                        <p> 01103, Україна, Київ, вул. Верхогляда Андрія, 9-Б</p>
                        <p>+38 (044) 359-07-50, +38 (050) 193-93-93,</p>
                        <p>+38 (068) 193-93-33</p>
                        <p>info@novoshkola.com.ua</p>
                    </div>
                    <div className='footer-osvitoria'>
                        <a href={OSVITORIA_URL} target='_blank' rel='noreferrer'>
                            <img src={osvitoriaLogo} alt='osvitoria logo' />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
