import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//states
import { selectAdminRole } from "features/authSlice";

import AuthService from "services/auth.service";

//styles
import "./style.scss";
import PageTitle from "components/PageTitle/PageTitle";
import Input from "components/UI/Input/Input";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import useAxios from "hooks/useAxios";
import API from "app/api";

const PAGE_TITLE = "Створення нового користувача";

const ROLE_OPTIONS = ["Адміністратор", "Модератор", "Користувач"];

const SignupPage = () => {
    const navigate = useNavigate();
    const hasAdminRole = useSelector(selectAdminRole);

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [roleTitle, setRoleTitle] = useState("Користувач");
    const [projectRoleTitle, setProjectRoleTitle] = useState("");
    const [projectRoleOptions, setProjectRoleOptions] = useState([]);

    const [isFormValid, setIsFormValid] = useState(false);

    const [message, setMessage] = useState("");

    const { response: projectRolesData } = useAxios({
        method: "get",
        url: API.projectRoles.readAll,
        headers: true,
    });

    useEffect(() => {
        if (projectRolesData) {
            const projectRoles = projectRolesData.map((projectRole) => projectRole.title);
            setProjectRoleOptions(projectRoles);
        }
    }, [projectRolesData]);

    useEffect(() => {
        if (!username) return setIsFormValid(false);
        if (!email) return setIsFormValid(false);
        if (!password) return setIsFormValid(false);
        if (!roleTitle) return setIsFormValid(false);
        if (!projectRoleTitle) return setIsFormValid(false);
        return setIsFormValid(true);
    }, [username, email, password, roleTitle, projectRoleTitle]);

    const usernameHandler = (value) => {
        setUsername(value);
    };

    const emailHandler = (value) => {
        setEmail(value);
    };

    const passwordHandler = (value) => {
        setPassword(value);
    };

    const roleHandler = (value) => {
        setRoleTitle(value);
    };

    const projectRoleTitleHandler = (value) => {
        setProjectRoleTitle(value);
    };

    const createUserHandler = (e) => {
        setMessage("");

        AuthService.register(username, email, password, roleTitle, projectRoleTitle).then(
            (response) => {
                setMessage(response.data.message);
                navigate("/users");
            },
            (error) => {
                const resMessage =
                    (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setMessage(resMessage);
            }
        );
    };

    return (
        <main className="container">
            <div className="page signup">
                <PageTitle pageTitle={PAGE_TITLE} />
                <div className="signup-form">
                    <Input label="Ім’я користувача" value={username} handler={usernameHandler} />
                    <Input label="Електронна пошта" value={email} handler={emailHandler} />
                    <Input label="Пароль" type="password" value={password} handler={passwordHandler} />
                    <Select label="Роль" value={roleTitle} options={ROLE_OPTIONS} handler={roleHandler} />
                    <Select label="Проєктна роль" value={projectRoleTitle} options={projectRoleOptions} handler={projectRoleTitleHandler} />
                </div>
                <ButtonGroup>
                    <Button content="Створити" handler={createUserHandler} disabled={!isFormValid} />
                </ButtonGroup>

                <div className="signup-message">{message}</div>
            </div>
        </main>
    );
};

export default SignupPage;
