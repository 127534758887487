import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components
import StageTitle from "./StageTitle";
import Input from "components/UI/Input/Input";
import Select from "components/UI/Select/Select";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";

//constants
import ASSESSMENTS from "constants/applicationAssessments";
import API from "app/api";
import AuthService from "services/auth.service";
import authHeader from "services/auth-header";
import Textarea from "components/UI/Textarea/Textarea";

const StageThreeAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const allowedEditorRoles = ["project manager", "director deputy"];
    const allowedViewerRoles = ["project manager", "director", "director deputy", "psychologist"];

    const points = applicationAssessment?.stageThreePoints;
    const comment = applicationAssessment?.stageThreeComment;
    const assessment = applicationAssessment?.stageThreeAssessment;

    const [stageThreePoints, setStageThreePoints] = useState(Number.isInteger(points) ? points : "");
    const [stageThreeComment, setStageThreeComment] = useState(comment || "");
    const [stageThreeAssessment, setStageThreeAssessment] = useState(assessment || "");

    const stageThreePointsHandler = (value) => {
        const onlyNumbersRegExp = new RegExp("^[0-9]+$");

        if (onlyNumbersRegExp.test(value)) {
            setStageThreePoints(value);
        } else if (!value) {
            setStageThreePoints("");
        }
    };

    const stageThreeCommentHandler = (value) => {
        setStageThreeComment(value);
    };

    const stageThreeAssessmentHandler = (value) => {
        setStageThreeAssessment(value);
    };

    const updateStageThree = () => {
        axios
            .post(
                API.assessment2025.updateStageThreeAssessment,
                { id, stageThreePoints, stageThreeComment, stageThreeAssessment },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    if (
        applicationAssessment?.stageTwoTeacherAssessment === "рекомендовано" &&
        applicationAssessment?.stageTwoPsychologistAssessment === "рекомендовано"
    ) {
        return (
            <div className="stage">
                <StageTitle title="Етап 3. Співбесіда" />

                {allowedViewerRoles.includes(userRole) &&
                    (Number.isInteger(points) && assessment ? (
                        <div className="stage-rated" data-assessment={assessment}>
                            <p className="stage-rated-points">{points}</p>
                            <p className="stage-rated-assessment">{assessment}</p>
                        </div>
                    ) : (
                        <div className="stage-not-rated">
                            <p>Не оцінено</p>
                        </div>
                    ))}

                {allowedEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <Input label="Кількість балів за співбесіду" value={stageThreePoints} handler={stageThreePointsHandler} />
                        <Textarea label="Коментар" value={stageThreeComment} handler={stageThreeCommentHandler} />

                        <Select
                            label="Фінальна оцінка"
                            options={ASSESSMENTS}
                            value={stageThreeAssessment}
                            handler={stageThreeAssessmentHandler}
                        />

                        <ButtonGroup>
                            <Button content="Зберегти" handler={updateStageThree} disabled={!(stageThreePoints && stageThreeAssessment)} />
                        </ButtonGroup>
                    </div>
                )}

                {!allowedViewerRoles.includes(userRole) && !allowedEditorRoles.includes(userRole) && (
                    <p className="stage-note">У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}
            </div>
        );
    }

    return (
        <div className="stage">
            <StageTitle title="Етап 3. Співбесіда" />
            <p className="stage-note">Анкета не отримала рекомендації на попередньому етапі.</p>
        </div>
    );
};

export default StageThreeAssessment;
