import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// states
import { useDispatch, useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

//API
import API from "app/api";

//services
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";

//components
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import SelectMultiple from "components/UI/SelectMultiple/SelectMultiple";
import Loader from "components/Loader/Loader";
import Select from "components/UI/Select/Select";
import PageMetadata from "components/PageMetadata/PageMetadata";
import PageTitle from "components/PageTitle/PageTitle";
import Pagination from "components/Pagination/Pagination";
import SearchInput from "components/UI/SearchInput/SearchInput";

//styles
import styles from "./styles.module.scss";

//icons
import TriangleDownIcon from "assets/icons/triangle-down.svg";
import { PROJECT_ROLE_NAME_BY_TITLES, PROJECT_ROLE_TITLE_BY_NAMES, PROJECT_ROLE_TITLES } from "constants/projectRoles";
import handleError from "api/handleError";

const PAGE_TITLE = "Анкети 2025 року";

const FUTURE_GRADE_OPTIONS = [8, 9, 10, 11];

const STAGES_OPTIONS = ["Всі анкети", "Етап 1", "Етап 2", "Етап 3"];

const STAGES = {
    "Всі анкети": undefined,
    "Етап 1": 1,
    "Етап 2": 2,
    "Етап 3": 3,
};

const SLAGS = {
    рекомендовано: "accepted",
    "не рекомендовано": "rejected",
    "потенційно рекомендовано": "questionable",
};

const LIMIT_OPTIONS = [10, 25, 50, 100, 200];

const TableHeaderWithTip = ({ content, tip, sorting, sort, order, handler, colSpan = 1 }) => {
    return (
        <th className={styles["table-header-with-tip"]} onClick={() => handler(sort)} colSpan={colSpan} data-col={sort}>
            <span className={styles["table-header-with-tip-content"]}>{content}</span>
            <span className={styles["table-header-with-tip-explanation"]}>
                ?<span>{tip}</span>
            </span>
            <span className={styles["table-header-with-sorting-control"]}>
                {sorting === sort && (
                    <span>
                        <img src={TriangleDownIcon} data-order={order} />
                    </span>
                )}
            </span>
        </th>
    );
};

const TableHeaderWithTipMultiple = ({ content, tip, sorting, order, sortArray, handler, colSpan, isAllowed = false }) => {
    return (
        <th className={styles["table-header-with-tip"]} colSpan={colSpan}>
            <span className={styles["table-header-with-tip-wrapper"]}>
                <span className={styles["table-header-divider"]}>
                    <span className={styles["table-header-with-tip-content"]}>{content}</span>
                    <span className={styles["table-header-with-tip-explanation"]}>
                        ?<span>{tip}</span>
                    </span>
                </span>
                <span className={styles["table-header-divider"]}>
                    {sortArray.map((sort) => {
                        return (
                            <span onClick={() => isAllowed && handler(sort.sorting)}>
                                {sort.title}
                                <span className={styles["table-header-with-sorting-control"]}>
                                    {sorting === sort.sorting && (
                                        <span>
                                            <img src={TriangleDownIcon} data-order={order} />
                                        </span>
                                    )}
                                </span>
                            </span>
                        );
                    })}
                </span>
            </span>
        </th>
    );
};

const TableHeaderWithSorting = ({ content, sorting, sort, order, handler }) => {
    return (
        <th className={styles["table-header-with-sorting"]} onClick={() => handler(sort)}>
            <span className={styles["table-header-with-sorting-content"]}>{content}</span>
            <span className={styles["table-header-with-sorting-control"]}>
                {sorting === sort && (
                    <span>
                        <img src={TriangleDownIcon} data-order={order} />
                    </span>
                )}
            </span>
        </th>
    );
};

const TableDataStageEmpty = () => {
    return (
        <td className={styles["table-data-stage"]}>
            <div className={styles["table-data-stage-wrapper"]}>
                <span className={styles["table-data-stage-default"]}>?</span>
            </div>
        </td>
    );
};

const TableDataStageSingle = ({ assessment, points }) => {
    const className = styles[`table-data-stage-${assessment ? SLAGS[assessment] : "default"}`];

    return (
        <td className={styles["table-data-stage"]}>
            <div className={styles["table-data-stage-wrapper"]}>
                <span className={className} data-result={assessment}>
                    {points || "-"}
                </span>
            </div>
        </td>
    );
};

const allowedRoles = ["project manager", "director", "director deputy", "psychologist"];

const ApplicationsPage2025 = () => {
    const pageAnchor = useRef();

    const isAdmin = useSelector(selectAdminRole);

    const userProjectRoleData = AuthService.getProjectRoleName();
    const onlyStageTwoAllowedRoles = ["math teacher", "ukrainian teacher", "english teacher", "nature teacher"];
    const defaultStage = onlyStageTwoAllowedRoles.includes(userProjectRoleData) ? 1 : undefined;
    const defaultselectedStage = onlyStageTwoAllowedRoles.includes(userProjectRoleData) ? "Етап 1" : "Всі анкети";

    const navigate = useNavigate();
    const dispacth = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const [userProjectRole, setUserProjectRole] = useState(userProjectRoleData);

    useEffect(() => {
        setUserProjectRole(userProjectRoleData);
    }, [userProjectRoleData]);

    const [applications, setApplications] = useState([]);

    const [userId, setUserId] = useState(AuthService.getCurrentUser().id);
    const [stage, setStage] = useState(defaultStage);
    const [selectedStage, setSelectedStage] = useState(defaultselectedStage);

    const [grades, setGrades] = useState([...FUTURE_GRADE_OPTIONS]);
    const [selectedGrades, setSelectedGrades] = useState([]);

    const [name, setName] = useState(undefined);
    const [nameSearch, setNameSearch] = useState("");

    const [sorting, setSorting] = useState("id");
    const [order, setOrder] = useState("DESC");

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(
                API.application2025.readAll,
                { sorting, order, selectedGrades, stage, name, limit, offset: (page - 1) * limit },
                { headers: authHeader() }
            )
            .then((response) => {
                setApplications(response.data?.rows);
                setPages(Array.from(Array(Math.ceil(response.data?.count / limit)).keys()));
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    setIsLoading(false);
                    handleError(error, dispacth, navigate);
                }
            });
    }, [sorting, order, selectedGrades, stage, name, page, limit]);

    const sortArray = (array) => {
        return array.sort((a, b) => a - b);
    };

    const userProjectRoleHandler = (value) => {
        setUserProjectRole(PROJECT_ROLE_NAME_BY_TITLES[value]);
    };

    const gradesHandler = (value) => {
        if (grades.includes(value)) {
            let newGrades = [...grades];
            const index = newGrades.indexOf(value);
            newGrades.splice(index, 1);

            setGrades(sortArray(newGrades));
            setSelectedGrades((selectedGrades) => sortArray([...selectedGrades, value]));
        } else {
            const newSelectedGrades = [...selectedGrades];
            const index = newSelectedGrades.indexOf(value);
            newSelectedGrades.splice(index, 1);

            setSelectedGrades(sortArray(newSelectedGrades));
            setGrades((grades) => sortArray([...grades, value]));
        }
    };

    const sortingHandler = (value) => {
        if (sorting !== value) {
            setSorting(value);
        } else {
            setOrder((prev) => (prev === "ASC" ? "DESC" : "ASC"));
        }
        setPage(1);
    };

    const selectedStageHandler = (value) => {
        setSelectedStage(value);
        setStage(STAGES[value]);
    };

    const pageHandler = (value) => {
        pageAnchor.current.scrollIntoView();
        setPage(value);
    };

    const downloadApplications = () => {
        axios
            .post(
                API.application2025.exportApplications,
                {
                    sorting,
                    order,
                    selectedGrades,
                    stage,
                },
                { headers: authHeader(), responseType: "blob" }
            )
            .then((response) => {
                const date = new Date();
                const fileName = `file (${date.toLocaleString("uk")}).csv`;
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement("a");

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const limitHandler = (value) => {
        setLimit(value);
    };

    const nameSearchHandler = (value) => {
        setNameSearch(value);
    };

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (!nameSearch) {
                return setName(undefined);
            }
            setName(nameSearch);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [nameSearch]);

    return (
        <>
            <PageMetadata title={PAGE_TITLE} />

            <main className="container" ref={pageAnchor}>
                <div className="page applications-page">
                    <PageTitle pageTitle={PAGE_TITLE} />

                    {isAdmin && (
                        <ButtonGroup>
                            <Button content="Анкети 2024 року" type="link" link={`/2024/applications`} />
                        </ButtonGroup>
                    )}
                    {isAdmin && (
                        <div className="assessment-form-role-simulator">
                            <Select
                                label="Переглянути анкети як"
                                value={PROJECT_ROLE_TITLE_BY_NAMES[userProjectRole]}
                                options={PROJECT_ROLE_TITLES}
                                handler={userProjectRoleHandler}
                            />
                        </div>
                    )}

                    <div className="applications-page-control">
                        <div>
                            <Select
                                label="Відобразити лише анкети, які пройшли"
                                options={STAGES_OPTIONS}
                                value={selectedStage}
                                handler={selectedStageHandler}
                                disabled={defaultStage}
                            />

                            <Select label="Результатів на сторінці" options={LIMIT_OPTIONS} value={limit} handler={limitHandler} />
                        </div>
                        <div>
                            <SelectMultiple
                                label="Майбутній клас апліканта"
                                options={grades}
                                selectedOptions={selectedGrades}
                                handler={gradesHandler}
                            />
                        </div>
                        {allowedRoles.includes(userProjectRole) && (
                            <div>
                                <SearchInput label="Пошук анкет за ПІБ" type="text" value={nameSearch} handler={nameSearchHandler} />
                            </div>
                        )}
                    </div>

                    {isAdmin && (
                        <ButtonGroup>
                            <Button content="Експортувати результати в csv" handler={downloadApplications} />
                        </ButtonGroup>
                    )}
                    <table className={styles["applications-page-table"]}>
                        <thead>
                            <tr>
                                <TableHeaderWithSorting content="ID" sorting={sorting} order={order} sort={"id"} handler={sortingHandler} />
                                <TableHeaderWithSorting
                                    content={allowedRoles.includes(userProjectRole) ? "ПІБ" : "Шифр"}
                                    sorting={sorting}
                                    order={order}
                                    sort={allowedRoles.includes(userProjectRole) ? "name" : "code"}
                                    handler={sortingHandler}
                                />
                                <TableHeaderWithSorting
                                    content="Майбутній клас"
                                    sorting={sorting}
                                    order={order}
                                    sort={"futureGrade"}
                                    handler={sortingHandler}
                                />

                                <TableHeaderWithTip
                                    content="Етап 1"
                                    tip="Оцінювання Заступником директора"
                                    sorting={sorting}
                                    order={order}
                                    sort={"stageOnePoints"}
                                    handler={sortingHandler}
                                />

                                <TableHeaderWithTipMultiple
                                    content="Етап 2"
                                    tip="Оцінювання Вчителями та Психологом"
                                    sorting={sorting}
                                    order={order}
                                    sortArray={[
                                        { title: "Математика", sorting: "stageTwoMathPoints" },
                                        { title: "Українська мова", sorting: "stageTwoUkrainianPoints" },
                                        { title: "Англійська мова", sorting: "stageTwoEnglishPoints" },
                                        { title: "Природничі науки", sorting: "stageTwoNaturePoints" },
                                        { title: "Середній бал", sorting: "sum" },
                                        { title: "Психолог", sorting: "stageTwoPsychologistAssessment" },
                                    ]}
                                    handler={sortingHandler}
                                    colSpan="6"
                                    isAllowed={allowedRoles.includes(userProjectRole)}
                                />
                                <TableHeaderWithTip
                                    content="Етап 3"
                                    tip="Оцінювання співбесіди"
                                    sorting={sorting}
                                    order={order}
                                    sort={"stageThreePoints"}
                                    handler={sortingHandler}
                                />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {applications.length > 0 ? (
                                applications.map((application) => {
                                    const { id, name, code, assessment } = application;
                                    return (
                                        <tr key={id}>
                                            <td>{id}</td>
                                            <td>{allowedRoles.includes(userProjectRole) ? name : code}</td>
                                            <td>{application.futureGrade}</td>

                                            {allowedRoles.includes(userProjectRole) ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageOneAssessment}
                                                    points={assessment?.stageOnePoints}
                                                />
                                            ) : (
                                                <TableDataStageSingle assessment={assessment?.stageOneAssessment} points={"?"} />
                                            )}

                                            {allowedRoles.includes(userProjectRole) || userProjectRole == "math teacher" ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageTwoMathAssessment}
                                                    points={assessment?.stageTwoMathPoints}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) || userProjectRole == "ukrainian teacher" ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageTwoUkrainianAssessment}
                                                    points={assessment?.stageTwoUkrainianPoints}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) || userProjectRole == "english teacher" ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageTwoEnglishAssessment}
                                                    points={assessment?.stageTwoEnglishPoints}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) || userProjectRole == "nature teacher" ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageTwoNatureAssessment}
                                                    points={assessment?.stageTwoNaturePoints}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) ? (
                                                <TableDataStageSingle
                                                    assessment={
                                                        assessment?.stageTwoTeacherAssessmentCompleted &&
                                                        assessment?.stageTwoTeacherAssessment
                                                    }
                                                    points={assessment?.sum && assessment?.sum !== 0 && assessment?.sum / 5}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) ? (
                                                <TableDataStageSingle assessment={assessment?.stageTwoPsychologistAssessment} />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}

                                            {allowedRoles.includes(userProjectRole) ? (
                                                <TableDataStageSingle
                                                    assessment={assessment?.stageThreeAssessment}
                                                    points={assessment?.stageThreePoints}
                                                />
                                            ) : (
                                                <TableDataStageEmpty />
                                            )}
                                            <td>
                                                <Button
                                                    content="Відкрити"
                                                    type="link"
                                                    link={`/2025/application/${application.id}`}
                                                    highlighted={false}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={12}>Анкет ще не отримано або анкети відсутні за вашим критерієм пошуку.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pagination page={page} pages={pages} handler={pageHandler} />
                </div>
            </main>
            {isLoading && <Loader />}
        </>
    );
};

export default ApplicationsPage2025;
