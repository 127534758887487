//components
import PageSubtitle from "components/UI/PageSubtitle/PageSubtitle";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";

//utilities
import parseDate from "features/parseDate";

//constants
import FORM_LABELS from "constants/formLabels2025";

//styles
import "./style.scss";

const booleanDataFormat = (data) => (data ? "Так" : "Ні");

function isValidUrl(string) {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" +
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" +
            "((\\d{1,3}\\.){3}\\d{1,3}))" +
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" +
            "(\\?[;&a-z\\d%_.~+=-]*)?" +
            "(\\#[-a-z\\d_]*)?$",
        "i"
    );
    return {
        valid: pattern.test(string),
        match: string.match(pattern),
    };
}

const checkIsValidUrl = (data) => {
    let check = isValidUrl(data);

    if (check.valid) {
        return <Button content={check.match[2] || "Покликання"} type="internal-link" link={data} />;
    }

    if (data.includes(" ")) {
        return data.split(/[\s,;]+/).map((string, index) => {
            string = string.replace(/\s/g, "");
            string = string.replace(/,/g, "");
            string = string.replace(/;/g, "");

            let check = isValidUrl(string);

            if (check.valid) {
                return <Button content={check.match[2] || "Покликання"} type="internal-link" link={string} key={index} />;
            }
        });
    }
    return <></>;
};

const ApplicationInformationSection = ({ data, field, withLink = false, withEmail = false }) => {
    return (
        <div className="application-information-section">
            <p>{FORM_LABELS[field]}</p>
            <p>{data || "Не вказано"}</p>
            {withEmail && (
                <ButtonGroup position="start">
                    <Button content="Написати на email" type="link" link={`mailto:${data}`} />
                </ButtonGroup>
            )}

            {withLink && <ButtonGroup position="start">{checkIsValidUrl(data)}</ButtonGroup>}
        </div>
    );
};

const ApplicationInformation2025 = ({ application, userProjectRole, printView }) => {
    const { name, code, birthdayDate } = application;
    const birthdayDateString = birthdayDate[0];

    const accessRoles = ["project manager", "director", "director deputy", "psychologist"];

    if (application) {
        return (
            <div className="application-information">
                <PageSubtitle pageSubtitle={accessRoles.includes(userProjectRole) ? name : code} />

                {!printView && (
                    <div className="application-information-note">
                        <span>Важливо!</span> Система буде автоматично намагатись генерувати покликання із даних, зазначених аплікантом в
                        анкеті, однак не всі дані можуть бути валідними покликаннями і коректно розпізнаватись. У цьому випадку потрібно
                        скопіювати дані в адресний рядок вашого браузера, відредагувати їх вручну та спробувати відкрити.
                    </div>
                )}
                
                {accessRoles.includes(userProjectRole) && (
                    <>
                        <ApplicationInformationSection data={birthdayDateString} field="birthdayDate" />
                        <ApplicationInformationSection data={application.informationSource} field="informationSource" />
                        <ApplicationInformationSection data={application.phoneNumber} field="phoneNumber" />
                        <ApplicationInformationSection data={application.email} field="email" withEmail={true} />
                        <ApplicationInformationSection data={application.futureGrade} field="futureGrade" />
                        <ApplicationInformationSection data={application.educationalInstitution} field="educationalInstitution" />
                        <ApplicationInformationSection data={application.settlement} field="settlement" />
                        <ApplicationInformationSection data={application.socialMediaLink} field="socialMediaLink" withLink={true} />
                        <ApplicationInformationSection data={application.photoLink} field="photoLink" withLink={true} />
                        <ApplicationInformationSection data={application.academicAchievements} field="academicAchievements" />
                        <ApplicationInformationSection
                            data={application.lastYearReportCardPhotoLink}
                            field="lastYearReportCardPhotoLink"
                            withLink={true}
                        />
                        <ApplicationInformationSection
                            data={application.currentYearFirstSemesterReportCardPhotoLink}
                            field="currentYearFirstSemesterReportCardPhotoLink"
                            withLink={true}
                        />
                        <ApplicationInformationSection
                            data={application.sportsAndCreativeAchievements}
                            field="sportsAndCreativeAchievements"
                        />
                        <ApplicationInformationSection data={application.socialActivity} field="socialActivity" />
                        <ApplicationInformationSection data={application.schoolActivity} field="schoolActivity" />
                        <ApplicationInformationSection data={application.motivation} field="motivation" />
                        <ApplicationInformationSection data={application.videoLink} field="videoLink" withLink={true} />
                        <ApplicationInformationSection data={application.aboutFamily} field="aboutFamily" />
                        <ApplicationInformationSection data={application.aboutApproachingVictory} field="aboutApproachingVictory" />
                        <ApplicationInformationSection data={application.contactPersonName} field="contactPersonName" />
                        <ApplicationInformationSection data={application.contactPersonEmail} field="contactPersonEmail" withEmail={true} />
                        <ApplicationInformationSection data={application.contactPersonPhone} field="contactPersonPhone" />
                        <ApplicationInformationSection
                            data={application.parentsSocialMediaLink}
                            field="parentsSocialMediaLink"
                            withLink={true}
                        />
                        <ApplicationInformationSection data={application.essay} field="essay" />
                        <ApplicationInformationSection data={booleanDataFormat(application.isConfirmed)} field="isConfirmed" />
                        <ApplicationInformationSection
                            data={booleanDataFormat(application.isAllowedDataProcessing)}
                            field="isAllowedDataProcessing"
                        />
                        <ApplicationInformationSection data={booleanDataFormat(application.isAcceptedTerms)} field="isAcceptedTerms" />
                    </>
                )}

                {!accessRoles.includes(userProjectRole) && (
                    <div className="application-information-access-denied">У вас немає доступу для перегляду інформації анкети.</div>
                )}
            </div>
        );
    }

    return null;
};
export default ApplicationInformation2025;
