import { addNotification } from "features/notificationSlice";
import { isAxiosError } from "axios";
import AuthService from "services/auth.service";

const handleError = (error, dispatch, navigate) => {
    let status;
    let message = "Щось пішло не так.";

    if (isAxiosError(error)) {
        status = error.response?.status;
        message = error.response?.data?.message || "Невідома помилка.";
    }

    if (status === 401) {
        AuthService.logout();
        navigate("/signin", { state: { message: "Час дії входу минув, увійдіть в систему повторно." } });
        window.location.reload();
        return;
    }

    if (status === 403) {
        navigate("/access-denied", { state: { message: "Доступ заборонено." } });
        return;
    }

    if (status === 404) {
        navigate("/404", { state: { message: "Сторінку не знайдено." } });
        return;
    }

    dispatch(
        addNotification({
            message,
            type: "error",
        })
    );
};

export default handleError;
