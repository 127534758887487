import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// services
import AuthService from "./services/auth.service";

// generic pages
import HomePage from "./pages/HomePage/HomePage";
import SigninPage from "./pages/SigninPage/SigninPage";
import SignupPage from "./pages/SignupPage/SignupPage";
import Profile from "./pages/Profile/Profile";
import UsersPage from "./pages/UsersPage/UsersPage";
import UserPage from "./pages/UserPage/UserPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import AccessDeniedPage from "./pages/AccessDeniedPage/AccessDeniedPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";

// specific pages
import ApplicationsPage2024 from "./pages/2024/ApplicationsPage2024/ApplicationsPage2024";
import ApplicationPage2024 from "./pages/2024/ApplicationPage2024/ApplicationPage2024";
import ApplicationsDashboardPage from "./pages/2024/ApplicationsDashboardPage/ApplicationsDashboardPage";
import CheckApplicationPage2024 from "./pages/2024/CheckApplicationPage2024/CheckApplicationPage2024";

import ApplicationsPage2025 from "pages/2025/ApplicationsPage2025/ApplicationsPage2025";
import ApplicationPage2025 from "pages/2025/ApplicationPage2025/ApplicationPage2025";
import CheckApplicationPage2025 from "./pages/2025/CheckApplicationPage2025/CheckApplicationPage2025";

// components
import Header from "./components/UI/Header/Header";
import AdminHeader from "./components/AdminHeader/AdminHeader";
import Footer from "./components/UI/Footer/Footer";
import Loader from "./components/Loader/Loader";

//event bus
import EventBus from "./common/EventBus";

//states
import { selectCurrentUser, selectModeratorRole, selectAdminRole, selectIsLogin } from "./features/authSlice";

//states control
import {
    setAdminRole,
    setCurrentUser,
    setModeratorRole,
    unsetAdminRole,
    unsetCurrentUser,
    unsetModeratorRole,
    userIsLogin,
    userIsLogout,
} from "./features/authSlice";

//styles
import "./reset.css";
import "./App.scss";
import ApplicationsDashboard2025Page from "pages/2025/ApplicationsDashboard2025Page/ApplicationsDashboard2025Page";
import Notification from "components/UI/Notification/Notification";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function RequireAuth({ children, redirectBase, administratorRoleRequired = false }) {
    const { id } = useParams();
    const currentUser = useSelector(selectCurrentUser);
    const isAdministrator = useSelector(selectAdminRole);

    let redirect;

    if (redirectBase && id) {
        redirect = `${redirectBase}/${id}`;
    } else if (redirectBase) {
        redirect = redirectBase;
    }

    if (currentUser && (!administratorRoleRequired || isAdministrator)) {
        return (
            <>
                <ScrollToTop />
                <div className="main-wrapper">
                    <div className="page-wrapper">
                        <AdminHeader />
                        {children}
                    </div>
                </div>
            </>
        );
    }

    return <SigninPage redirect={redirect} />;
}

function Wrapper({ children }) {
    return (
        <>
            <ScrollToTop />
            <div className="main-wrapper client-wrapper">
                <div className="page-wrapper">
                    <AdminHeader />
                    <Header />
                    {children}
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default function App() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            dispatch(userIsLogin());
            dispatch(setCurrentUser(user));
            console.log(user.roles);

            if (user.roles.includes("ROLE_MODERATOR")) dispatch(setModeratorRole());
            if (user.roles.includes("ROLE_ADMINISTRATOR")) dispatch(setAdminRole());
        }

        setIsLoading(false);

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = () => {
        AuthService.logout();
        dispatch(userIsLogout());
        dispatch(unsetCurrentUser());
        dispatch(unsetModeratorRole());
        dispatch(unsetAdminRole());
    };

    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Wrapper>
                            <HomePage />
                        </Wrapper>
                    }
                />

                <Route
                    path="check-applications"
                    element={
                        <Wrapper>
                            <CheckApplicationPage2025 />
                        </Wrapper>
                    }
                />

                <Route path="/signin" element={<SigninPage />} />
                <Route path="/error" element={<ErrorPage />} />
                <Route path="*" element={<NotFoundPage />} />

                <Route
                    path="2024/applications"
                    element={
                        <RequireAuth redirectBase="/2024/applications" administratorRoleRequired={true}>
                            <ApplicationsPage2024 />
                        </RequireAuth>
                    }
                />

                <Route
                    path="2025/applications"
                    element={
                        <RequireAuth redirectBase="/2025/applications">
                            <ApplicationsPage2025 />
                        </RequireAuth>
                    }
                />

                <Route path="2024/application">
                    <Route
                        path=":id"
                        element={
                            <RequireAuth redirectBase="/2024/application" administratorRoleRequired={true}>
                                <ApplicationPage2024 />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path="2025/application">
                    <Route
                        path=":id"
                        element={
                            <RequireAuth redirectBase="/2025/application">
                                <ApplicationPage2025 />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path="2024/dashboard"
                    element={
                        <RequireAuth redirectBase="/applications" administratorRoleRequired={true}>
                            <ApplicationsDashboardPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path="2025/dashboard"
                    element={
                        <RequireAuth redirectBase="/applications">
                            <ApplicationsDashboard2025Page />
                        </RequireAuth>
                    }
                />

                <Route
                    path="profile"
                    element={
                        <RequireAuth>
                            <Profile />
                        </RequireAuth>
                    }
                />

                <Route
                    path="access-denied"
                    element={
                        <RequireAuth>
                            <AccessDeniedPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path="signup"
                    element={
                        <RequireAuth>
                            <SignupPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path="users"
                    element={
                        <RequireAuth>
                            <UsersPage />
                        </RequireAuth>
                    }
                />

                <Route path="user">
                    <Route
                        path=":userId"
                        element={
                            <RequireAuth>
                                <UserPage />
                            </RequireAuth>
                        }
                    />
                </Route>
            </Routes>
            <Notification />
            {isLoading && <Loader />}
        </>
    );
}
