import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//states
import { selectCurrentUser, selectAdminRole } from "../../features/authSlice";

//event bus
import eventBus from "../../common/EventBus";

//styles
import "./style.scss";

//icons
import userIcon from "assets/icons/user-icon.svg";
import AuthService from "services/auth.service";

const allowedRoles = ["project manager", "director", "director deputy", "psychologist"];

export default function AdminHeader() {
    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);
    const userProjectRole = AuthService.getProjectRoleName();

    const logOutHandler = () => {
        eventBus.dispatch("logout");
    };

    return (
        <>
            {currentUser && (
                <div className="header-admin-bar">
                    <div className="header-admin-bar-nav">
                        <Link to="/">Форма</Link>
                        <Link to="/check-applications">Перевірити отримання</Link>
                        <span>|</span>
                        <Link to="/2025/applications">Всі заявки</Link>
                        {allowedRoles.includes(userProjectRole) && <Link to="/2025/dashboard">Дашборд</Link>}
                        {isAdmin && <Link to="/users">Користувачі</Link>}
                    </div>

                    <div className="header-admin-bar-control">
                        <div className="header-admin-bar-control-icon">
                            <img src={userIcon} alt="User Icon" />
                        </div>
                        <Link to={"/profile"} className="header-admin-bar-control-info">
                            <div>{currentUser.username}</div>
                        </Link>

                        <div className="header-admin-bar-control-menu">
                            <Link to={"/profile"}>Профіль</Link>
                            <a href="/signin" onClick={logOutHandler}>
                                Вийти
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
