import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// components
import StageTitle from "./StageTitle";
import StageSubTitle from "./StageSubTitle";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Input from "components/UI/Input/Input";
import Select from "components/UI/Select/Select";
import Textarea from "components/UI/Textarea/Textarea";

// services
import AuthService from "services/auth.service";
import authHeader from "services/auth-header";

// api
import API from "app/api";

// constants
import ASSESSMENTS from "constants/applicationAssessments";

const allowedTeacherViewerRoles = ["project manager", "director", "director deputy", "psychologist"];
const allowedMathEditorRoles = ["project manager", "math teacher"];
const allowedUkrainianEditorRoles = ["project manager", "ukrainian teacher"];
const allowedEnglishEditorRoles = ["project manager", "english teacher"];
const allowedNatureEditorRoles = ["project manager", "nature teacher"];

const allowedPsychologistViewerRoles = ["project manager", "director", "director deputy", "psychologist"];
const allowedPsychologistEditorRoles = ["project manager", "psychologist"];

const StageTwoAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const mathPoints = applicationAssessment?.stageTwoMathPoints;
    const mathComment = applicationAssessment?.stageTwoMathComment;
    const mathAssessment = applicationAssessment?.stageTwoMathAssessment;

    const ukrainianPoints = applicationAssessment?.stageTwoUkrainianPoints;
    const ukrainianComment = applicationAssessment?.stageTwoUkrainianComment;
    const ukrainianAssessment = applicationAssessment?.stageTwoUkrainianAssessment;

    const englishPoints = applicationAssessment?.stageTwoEnglishPoints;
    const englishComment = applicationAssessment?.stageTwoEnglishComment;
    const englishAssessment = applicationAssessment?.stageTwoEnglishAssessment;

    const naturePoints = applicationAssessment?.stageTwoNaturePoints;
    const natureComment = applicationAssessment?.stageTwoNatureComment;
    const natureAssessment = applicationAssessment?.stageTwoNatureAssessment;

    const teacherAssessment = applicationAssessment?.stageTwoTeacherAssessment;
    const stageTwoTeacherAssessmentCompleted = applicationAssessment?.stageTwoTeacherAssessmentCompleted;

    const psychologistComment = applicationAssessment?.stageTwoPsychologistComment;
    const psychologistAssessment = applicationAssessment?.stageTwoPsychologistAssessment;

    const [stageTwoMathPoints, setStageTwoMathPoints] = useState(mathPoints || "");
    const [stageTwoMathComment, setStageTwoMathComment] = useState(mathComment || "");
    const [stageTwoMathAssessment, setStageTwoMathAssessment] = useState(mathAssessment || "");

    const [stageTwoUkrainianPoints, setStageTwoUkrainianPoints] = useState(ukrainianPoints || "");
    const [stageTwoUkrainianComment, setStageTwoUkrainianComment] = useState(ukrainianComment || "");
    const [stageTwoUkrainianAssessment, setStageTwoUkrainianAssessment] = useState(ukrainianAssessment || "");

    const [stageTwoEnglishPoints, setStageTwoEnglishPoints] = useState(englishPoints || "");
    const [stageTwoEnglishComment, setStageTwoEnglishComment] = useState(englishComment || "");
    const [stageTwoEnglishAssessment, setStageTwoEnglishAssessment] = useState(englishAssessment || "");

    const [stageTwoNaturePoints, setStageTwoNaturePoints] = useState(naturePoints || "");
    const [stageTwoNatureComment, setStageTwoNatureComment] = useState(natureComment || "");
    const [stageTwoNatureAssessment, setStageTwoNatureAssessment] = useState(natureAssessment || "");

    const [stageTwoPsychologistComment, setStageTwoPsychologistComment] = useState(psychologistComment || "");
    const [stageTwoPsychologistAssessment, setStageTwoPsychologistAssessment] = useState(psychologistAssessment || "");

    const pointsValidator = (value, setPoints) => {
        const onlyNumbersRegExp = new RegExp("^[0-9]+$");
        const isValid = +value >= 1 && +value <= 12;

        if (onlyNumbersRegExp.test(value) && isValid) {
            setPoints(value);
        } else if (!value) {
            setPoints("");
        }
    };

    const stageTwoMathPointsHandler = (value) => pointsValidator(value, setStageTwoMathPoints);
    const stageTwoMathAssessmentHandler = (value) => setStageTwoMathAssessment(value);
    const stageTwoMathCommentHandler = (value) => setStageTwoMathComment(value);

    const stageTwoUkrainianPointsHandler = (value) => pointsValidator(value, setStageTwoUkrainianPoints);
    const stageTwoUkrainianAssessmentHandler = (value) => setStageTwoUkrainianAssessment(value);
    const stageTwoUkrainianCommentHandler = (value) => setStageTwoUkrainianComment(value);

    const stageTwoEnglishPointsHandler = (value) => pointsValidator(value, setStageTwoEnglishPoints);
    const stageTwoEnglishAssessmentHandler = (value) => setStageTwoEnglishAssessment(value);
    const stageTwoEnglishCommentHandler = (value) => setStageTwoEnglishComment(value);

    const stageTwoNaturePointsHandler = (value) => pointsValidator(value, setStageTwoNaturePoints);
    const stageTwoNatureAssessmentHandler = (value) => setStageTwoNatureAssessment(value);
    const stageTwoNatureCommentHandler = (value) => setStageTwoNatureComment(value);

    const stageTwoPsychologistCommentHandler = (value) => setStageTwoPsychologistComment(value);
    const stageTwoPsychologistAssessmentHandler = (value) => setStageTwoPsychologistAssessment(value);

    const updateStageTwoMath = () => {
        axios
            .post(
                API.assessment2025.updateStageTwoMath,
                { id, stageTwoMathPoints, stageTwoMathComment, stageTwoMathAssessment },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateStageTwoUkrainian = () => {
        axios
            .post(
                API.assessment2025.updateStageTwoUkrainian,
                {
                    id,
                    stageTwoUkrainianPoints,
                    stageTwoUkrainianComment,
                    stageTwoUkrainianAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateStageTwoEnglish = () => {
        axios
            .post(
                API.assessment2025.updateStageTwoEnglish,
                {
                    id,
                    stageTwoEnglishPoints,
                    stageTwoEnglishComment,
                    stageTwoEnglishAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateStageTwoNature = () => {
        axios
            .post(
                API.assessment2025.updateStageTwoNature,
                {
                    id,
                    stageTwoNaturePoints,
                    stageTwoNatureComment,
                    stageTwoNatureAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateStageTwoPsychologist = () => {
        axios
            .post(
                API.assessment2025.updateStageTwoPsychologistAssessment,
                {
                    id,
                    stageTwoPsychologistComment,
                    stageTwoPsychologistAssessment,
                },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    if (applicationAssessment?.stageOneAssessment === "рекомендовано") {
        return (
            <div className="stage">
                <StageTitle title="Етап 2. Оцінки тестування та психолога" />

                <StageSubTitle subTitle="Оцінки тестування" />

                {allowedTeacherViewerRoles.includes(userRole) ? (
                    true ? (
                        <div className="stage-rated" data-assessment={teacherAssessment}>
                            <p className="stage-rated-points">{(mathPoints + englishPoints + ukrainianPoints + naturePoints) / 5}</p>
                            <p className="stage-rated-assessment">
                                {stageTwoTeacherAssessmentCompleted ? teacherAssessment : "оцінювання не завершено"}
                            </p>
                            <div className="stage-rated-subject">
                                <p>Математика</p>
                                <p>
                                    <span>Кількість балів:</span> {mathPoints || "не оцінено"}
                                </p>
                                <p>
                                    <span>Коментар:</span> {mathComment || "не оцінено"}
                                </p>
                                <p>
                                    <span>Рекомендація:</span> {mathAssessment || "не оцінено"}
                                </p>
                            </div>
                            <div className="stage-rated-subject">
                                <p>Українська мова</p>
                                <p>
                                    <span>Кількість балів:</span> {ukrainianPoints || "не оцінено"}
                                </p>
                                <p>
                                    <span>Коментар:</span> {ukrainianComment || "не оцінено"}
                                </p>
                                <p>
                                    <span>Рекомендація:</span> {ukrainianAssessment || "не оцінено"}
                                </p>
                            </div>
                            <div className="stage-rated-subject">
                                <p>Англійська мова</p>
                                <p>
                                    <span>Кількість балів:</span> {englishPoints || "не оцінено"}
                                </p>
                                <p>
                                    <span>Коментар:</span> {englishComment || "не оцінено"}
                                </p>
                                <p>
                                    <span>Рекомендація:</span> {englishAssessment || "не оцінено"}
                                </p>
                            </div>
                            <div className="stage-rated-subject">
                                <p>Природничі науки</p>
                                <p>
                                    <span>Кількість балів:</span> {naturePoints || "не оцінено"}
                                </p>
                                <p>
                                    <span>Коментар:</span> {natureComment || "не оцінено"}
                                </p>
                                <p>
                                    <span>Рекомендація:</span> {natureAssessment || "не оцінено"}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="stage-not-rated">
                            <p>Не оцінено</p>
                        </div>
                    )
                ) : (
                    <p className="stage-access-denied">У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}

                {allowedMathEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <StageSubTitle subTitle="Математика" />
                        <Input label="Кількість балів за Математику" value={stageTwoMathPoints} handler={stageTwoMathPointsHandler} />
                        <Textarea label="Коментар вчителя Математики" value={stageTwoMathComment} handler={stageTwoMathCommentHandler} />
                        <Select
                            label="Фінальна оцінка за Математику"
                            options={ASSESSMENTS}
                            value={stageTwoMathAssessment}
                            handler={stageTwoMathAssessmentHandler}
                        />
                        <ButtonGroup>
                            <Button
                                content="Зберегти"
                                handler={updateStageTwoMath}
                                disabled={!(stageTwoMathPoints && stageTwoMathComment && stageTwoMathAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}

                {allowedUkrainianEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <StageSubTitle subTitle="Українська мова" />
                        <Input
                            label="Кількість балів за Українську мову"
                            value={stageTwoUkrainianPoints}
                            handler={stageTwoUkrainianPointsHandler}
                        />

                        <Textarea
                            label="Коментар вчителя Української мови"
                            value={stageTwoUkrainianComment}
                            handler={stageTwoUkrainianCommentHandler}
                        />
                        <Select
                            label="Фінальна оцінка за Українську мову"
                            options={ASSESSMENTS}
                            value={stageTwoUkrainianAssessment}
                            handler={stageTwoUkrainianAssessmentHandler}
                        />
                        <ButtonGroup>
                            <Button
                                content="Зберегти"
                                handler={updateStageTwoUkrainian}
                                disabled={!(stageTwoUkrainianPoints && stageTwoUkrainianComment && stageTwoUkrainianAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}

                {allowedEnglishEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <StageSubTitle subTitle="Англійська мова" />

                        <Input
                            label="Кількість балів за Англійську мову"
                            value={stageTwoEnglishPoints}
                            handler={stageTwoEnglishPointsHandler}
                        />

                        <Textarea
                            label="Коментар вчителя Англійської мови"
                            value={stageTwoEnglishComment}
                            handler={stageTwoEnglishCommentHandler}
                        />
                        <Select
                            label="Фінальна оцінка за Англійську мову"
                            options={ASSESSMENTS}
                            value={stageTwoEnglishAssessment}
                            handler={stageTwoEnglishAssessmentHandler}
                        />
                        <ButtonGroup>
                            <Button
                                content="Зберегти"
                                handler={updateStageTwoEnglish}
                                disabled={!(stageTwoEnglishPoints && stageTwoEnglishComment && stageTwoEnglishAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}

                {allowedNatureEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <StageSubTitle subTitle="Природничі науки" />
                        <Input
                            label="Кількість балів за Природничі науки"
                            value={stageTwoNaturePoints}
                            handler={stageTwoNaturePointsHandler}
                        />

                        <Textarea
                            label="Коментар вчителя Природничих наук"
                            value={stageTwoNatureComment}
                            handler={stageTwoNatureCommentHandler}
                        />
                        <Select
                            label="Фінальна оцінка за Природничі науки"
                            options={ASSESSMENTS}
                            value={stageTwoNatureAssessment}
                            handler={stageTwoNatureAssessmentHandler}
                        />
                        <ButtonGroup>
                            <Button
                                content="Зберегти"
                                handler={updateStageTwoNature}
                                disabled={!(stageTwoNaturePoints && stageTwoNatureComment && stageTwoNatureAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}

                <StageSubTitle subTitle="Оцінка психолога" />

                {allowedPsychologistViewerRoles.includes(userRole) ? (
                    psychologistAssessment ? (
                        <div className="stage-rated" data-assessment={psychologistAssessment}>
                            <p className="stage-rated-assessment">{psychologistAssessment}</p>
                            <p className="stage-rated-comment">{psychologistComment}</p>
                        </div>
                    ) : (
                        <div className="stage-not-rated">
                            <p>Не оцінено</p>
                        </div>
                    )
                ) : (
                    <p className="stage-note">У вас немає прав на перегляд оцінювання цього етапу.</p>
                )}

                {allowedPsychologistEditorRoles.includes(userRole) && (
                    <div className="stage-form">
                        <Textarea
                            label="Коментар психолога"
                            value={stageTwoPsychologistComment}
                            handler={stageTwoPsychologistCommentHandler}
                        />

                        <Select
                            label="Фінальна оцінка психолога"
                            options={ASSESSMENTS}
                            value={stageTwoPsychologistAssessment}
                            handler={stageTwoPsychologistAssessmentHandler}
                        />

                        <ButtonGroup>
                            <Button
                                content="Зберегти"
                                handler={updateStageTwoPsychologist}
                                disabled={!(stageTwoPsychologistComment && stageTwoPsychologistAssessment)}
                            />
                        </ButtonGroup>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="stage">
            <StageTitle title="Етап 2. Оцінки тестування та психолога" />
            <p className="stage-note">Анкета не отримала рекомендації на попередньому етапі.</p>
        </div>
    );
};

export default StageTwoAssessment;
