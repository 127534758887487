import { useSelector } from "react-redux";

//states
import { selectAdminRole } from "../../features/authSlice";

//components
import PageSubtitle from "../UI/PageSubtitle/PageSubtitle";
import Select from "../UI/Select/Select";
import StageOneAssessment from "./components/StageOneAssessment";
import StageTwoAssessment from "./components/StageTwoAssessment";
import StageThreeAssessment from "./components/StageThreeAssessment";

//styles
import "./style.scss";

//constants
const USER_ROLES = [
    "Менеджер проєкту",
    "Директор",
    "Заступник директора",
    "Вчитель Математики",
    "Вчитель Української мови",
    "Вчитель Англійської мови",
    "Вчитель Природничих наук",
    "Психолог",
];

const USER_ROLES_TITLES = {
    "project manager": "Менеджер проєкту",
    director: "Директор",
    "director deputy": "Заступник директора",
    "math teacher": "Вчитель Математики",
    "ukrainian teacher": "Вчитель Української мови",
    "english teacher": "Вчитель Англійської мови",
    "nature teacher": "Вчитель Природничих наук",
    psychologist: "Психолог",
};

const AssessmentForm2025 = ({ id, applicationAssessment, userProjectRole, userProjectRoleHandler, printView }) => {
    const isAdmin = useSelector(selectAdminRole);

    return (
        <div className="assessment-form">
            <PageSubtitle pageSubtitle="Оцінювання" />
            {isAdmin && !printView && (
                <div className="assessment-form-role-simulator">
                    <Select
                        label="Переглянути оцінювання як"
                        value={USER_ROLES_TITLES[userProjectRole]}
                        options={USER_ROLES}
                        handler={userProjectRoleHandler}
                    />
                </div>
            )}
            <StageOneAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
            <StageTwoAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
            <StageThreeAssessment id={id} userRole={userProjectRole} applicationAssessment={applicationAssessment} />
        </div>
    );
};

export default AssessmentForm2025;
