import { combineReducers, createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: "notifications",
    initialState: [],
    reducers: {
        addNotification: (state, action) => {
            state.push({ id: Date.now(), message: action.payload.message, type: action.payload.type });
        },
        removeNotification: (state, action) => {
            return state.filter((notification) => notification.id !== action.payload);
        },
    },
});

export const { addNotification, removeNotification } = notificationSlice.actions;
export const selectNotifications = (state) => state.notifications.notifications;

export default combineReducers({
    notifications: notificationSlice.reducer,
});
