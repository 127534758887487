import { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

//hooks
import useAxios from "hooks/useAxios";

//components
import PageMetadata from "components/PageMetadata/PageMetadata";
import PageTitle from "components/PageTitle/PageTitle";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";

//api
import API from "app/api";

//styles
import "./style.scss";

//initialization
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend);

//constants
const PAGE_TITLE = "Дашборд 2024 року";

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
};

const chartConfigConstructor = (data) => {
    return {
        labels: ["Рекомендовано", "Потенційно рекомендовано", "Не рекомендовано", "Не оцінено"],
        datasets: [
            {
                label: "Кількість анкет",
                data,
                backgroundColor: ["#7bbe26", "#ffa500", "#ff0000", "#f6f6f6"],
            },
        ],
    };
};

const barChartConfigConstructor = (data) => {
    return {
        labels: ["Етап 1", "Етап 2", "Етап 3", "Етап 4"],
        datasets: [
            {
                label: "Рекомендовано",
                data: data.datasetOne,
                backgroundColor: ["#7bbe26"],
            },
            {
                label: "Потенційно рекомендовано",
                data: data.datasetTwo,
                backgroundColor: ["#ffa500"],
            },
            {
                label: "Не рекомендовано",
                data: data.datasetThree,
                backgroundColor: ["#ff0000"],
            },
            {
                label: "Не оцінено",
                data: data.datasetFour,
                backgroundColor: ["#f6f6f6"],
            },
        ],
    };
};

const DoughnutChartWrapper = ({ data, options }) => {
    const titleRef = useRef();

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.render();
            titleRef.current.update();
        }
    }, [data]);

    if (data) {
        return (
            <div className="dasboard-chart">
                <Doughnut ref={titleRef} data={data} options={options} />
            </div>
        );
    }

    return <></>;
};

const BarChartWrapper = ({ data, options }) => {
    const titleRef = useRef();

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.render();
            titleRef.current.update();
        }
    }, [data]);

    if (data) {
        return (
            <div className="dasboard-chart">
                <Bar ref={titleRef} data={data} options={options} />
            </div>
        );
    }

    return <></>;
};

const ApplicationsDashboardPage = () => {
    const [statistics, setStatistics] = useState({});
    const [dataStageOne, setDataStageOne] = useState(undefined);
    const [dataStageTwo, setDataStageTwo] = useState(undefined);
    const [dataStageThree, setDataStageThree] = useState(undefined);
    const [dataStageFour, setDataStageFour] = useState(undefined);
    const [barChartDataset, setBarChartDataset] = useState(undefined);

    const { response: statisticsData, statisticsLoading } = useAxios({
        method: "get",
        url: API.assessment2024.readAssessmentStatistic,
        headers: true,
    });

    useEffect(() => {
        if (statisticsData !== null) {
            const {
                totalNotPassed,
                stageOnePassed,
                stageOneDisputed,
                stageOneNotPassed,
                stageOneNotRated,
                stageTwoPassed,
                stageTwoDisputed,
                stageTwoNotPassed,
                stageTwoNotRated,
                stageThreePassed,
                stageThreeDisputed,
                stageThreeNotPassed,
                stageThreeNotRated,
                stageFourPassed,
                stageFourDisputed,
                stageFourNotPassed,
                stageFourNotRated,
            } = statisticsData;

            setStatistics(statisticsData);
            setDataStageOne(chartConfigConstructor([stageOnePassed, stageOneDisputed, stageOneNotPassed, stageOneNotRated]));
            setDataStageTwo(chartConfigConstructor([stageTwoPassed, stageTwoDisputed, stageTwoNotPassed, stageTwoNotRated]));
            setDataStageThree(chartConfigConstructor([stageThreePassed, stageThreeDisputed, stageThreeNotPassed, stageThreeNotRated]));
            setDataStageFour(chartConfigConstructor([stageFourPassed, stageFourDisputed, stageFourNotPassed, stageFourNotRated]));
            setBarChartDataset(
                barChartConfigConstructor({
                    datasetOne: [stageOnePassed, stageTwoPassed, stageThreePassed, stageFourPassed],
                    datasetTwo: [stageOneDisputed, stageTwoDisputed, stageThreeDisputed, stageFourDisputed],
                    datasetThree: [stageOneNotPassed, stageTwoNotPassed, stageThreeNotPassed, stageFourNotPassed],
                    datasetFour: [stageOneNotRated, stageTwoNotRated, stageThreeNotRated, stageFourNotRated],
                })
            );
        }

        if (!statisticsLoading) {
            // setIsLoading(false);
        }
    }, [statisticsData]);

    return (
        <>
            <PageMetadata title={PAGE_TITLE} />

            <main className="container">
                <div className="page applications-dashboard-page">
                    <PageTitle pageTitle={PAGE_TITLE} />

                    <ButtonGroup>
                        <Button content="Дашборд 2025 року" type="link" link={`/2025/dashboard`} />
                    </ButtonGroup>

                    <div className="applications-dashboard-page-chart-bar">
                        <BarChartWrapper data={barChartDataset} />
                    </div>

                    <div className="applications-dashboard-page-widgets">
                        <div className="applications-dashboard-page-widget">
                            <h3>Етап 1</h3>
                            <DoughnutChartWrapper data={dataStageOne} options={options} />
                            <p>
                                Всього анкет: <span>{statistics.stageOneTotal}</span>
                            </p>
                            <p>
                                Рекомендовано: <span>{statistics.stageOnePassed}</span>
                            </p>
                            <p>
                                Потенційно рекомендовано: <span>{statistics.stageOneDisputed}</span>
                            </p>
                            <p>
                                Не рекомендовано: <span>{statistics.stageOneNotPassed}</span>
                            </p>
                            <p>
                                Не оцінено: <span>{statistics.stageOneNotRated}</span>
                            </p>
                        </div>

                        <div className="applications-dashboard-page-widget">
                            <h3>Етап 2</h3>
                            {statistics.stageOnePassed === "0" ? (
                                <div className="applications-dashboard-page-widget-note">Дані для відображення відсутні</div>
                            ) : (
                                <DoughnutChartWrapper data={dataStageTwo} options={options} />
                            )}
                            <p>
                                Всього анкет: <span>{statistics.stageTwoTotal}</span>
                            </p>
                            <p>
                                Рекомендовано: <span>{statistics.stageTwoPassed}</span>
                            </p>
                            <p>
                                Потенційно рекомендовано: <span>{statistics.stageTwoDisputed}</span>
                            </p>
                            <p>
                                Не рекомендовано: <span>{statistics.stageTwoNotPassed}</span>
                            </p>
                            <p>
                                Не оцінено: <span>{statistics.stageTwoNotRated}</span>
                            </p>
                        </div>

                        <div className="applications-dashboard-page-widget">
                            <h3>Етап 3</h3>
                            {statistics.stageTwoPassed === "0" ? (
                                <div className="applications-dashboard-page-widget-note">Дані для відображення відсутні</div>
                            ) : (
                                <DoughnutChartWrapper data={dataStageThree} options={options} />
                            )}
                            <p>
                                Всього анкет: <span>{statistics.stageThreeTotal}</span>
                            </p>
                            <div>
                                <p>Рекомендовано:</p>
                                <ul>
                                    <li>
                                        вчителем - <span>{statistics.stageThreeTeacherPassed}</span>
                                    </li>
                                    <li>
                                        психологом - <span>{statistics.stageThreePsychologistPassed}</span>
                                    </li>
                                    <li>
                                        за етап - <span>{statistics.stageThreePassed}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p>Потенційно рекомендовано:</p>
                                <ul>
                                    <li>
                                        вчителем - <span>{statistics.stageThreeTeacherDisputed}</span>
                                    </li>
                                    <li>
                                        психологом - <span>{statistics.stageThreePsychologistDisputed}</span>
                                    </li>
                                    <li>
                                        за етап - <span>{statistics.stageThreeDisputed}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p>Не рекомендовано:</p>
                                <ul>
                                    <li>
                                        вчителем - <span>{statistics.stageThreeTeacherNotPassed}</span>
                                    </li>
                                    <li>
                                        психологом - <span>{statistics.stageThreePsychologistNotPassed}</span>
                                    </li>
                                    <li>
                                        за етап - <span>{statistics.stageThreeNotPassed}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p>Не оцінено:</p>
                                <ul>
                                    <li>
                                        вчителем - <span>{statistics.stageThreeTeacherNotRated}</span>
                                    </li>
                                    <li>
                                        психологом -<span>{statistics.stageThreePsychologistNotRated}</span>
                                    </li>
                                    <li>
                                        всього - <span>{statistics.stageThreeNotRated}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="applications-dashboard-page-widget">
                            <h3>Етап 4</h3>
                            {statistics.stageThreePassed === "0" ? (
                                <div className="applications-dashboard-page-widget-note">Дані для відображення відсутні</div>
                            ) : (
                                <DoughnutChartWrapper data={dataStageFour} options={options} />
                            )}
                            <p>
                                Всього анкет: <span>{statistics.stageFourTotal}</span>
                            </p>
                            <p>
                                Рекомендовано: <span>{statistics.stageFourPassed}</span>
                            </p>
                            <p>
                                Потенційно рекомендовано: <span>{statistics.stageFourDisputed}</span>
                            </p>
                            <p>
                                Не рекомендовано: <span>{statistics.stageFourNotPassed}</span>
                            </p>
                            <p>
                                Не оцінено: <span>{statistics.stageFourNotRated}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ApplicationsDashboardPage;
