import { useEffect, useState } from "react";

// states
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/authSlice";

// components
import PageMetadata from "components/PageMetadata/PageMetadata";
import ApplicationForm from "components/ApplicationForm/ApplicationForm";
import LandingInformation from "components/LandingInformation/LandingInformation";

// styles
import "./style.scss";

//constants
const PAGE_TITLE = "Стипендіальна програма Новопечерської школи";
const PAGE_DESCRIPTION = "Стипендіальна програма — це грант від Новопечерської школи на безоплатне навчання";

export default function HomePage() {
    const user = useSelector(selectCurrentUser);
    const [nowDate, setNowDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setNowDate(new Date());
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const getKyivTime = (date) => {
        const options = { timeZone: "Europe/Kyiv", hour12: false };
        const kyivDate = new Date(date.toLocaleString("en-US", { ...options, timeZoneName: "short" }));
        return kyivDate;
    };

    const kyivNow = getKyivTime(nowDate);
    const startDate = new Date(Date.UTC(2025, 1, 1, 0, 0, 0));
    const endDate = new Date(Date.UTC(2025, 2, 17, 0, 0, 0));

    const isUnregisteredUserAndValidTime = !user && kyivNow >= startDate && kyivNow < endDate;
    console.log(kyivNow);

    return (
        <>
            <PageMetadata title={PAGE_TITLE} description={PAGE_DESCRIPTION} />

            <main className="container">
                <div className="home-page page">
                    <LandingInformation nowDate={nowDate} startDate={startDate} endDate={endDate} />
                    {user && (
                        <p className="home-page-alert">
                            Важливо! Користувачі системи оцінювання бачать форму, навіть у період, коли вона є закритою.
                        </p>
                    )}
                    {(isUnregisteredUserAndValidTime || user) && <ApplicationForm />}
                </div>
            </main>
        </>
    );
}
