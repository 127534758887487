import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// components
import StageTitle from "./StageTitle";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Input from "components/UI/Input/Input";
import Select from "components/UI/Select/Select";
import Textarea from "components/UI/Textarea/Textarea";

// constants
import ASSESSMENTS from "constants/applicationAssessments";
import API from "app/api";
import AuthService from "services/auth.service";
import authHeader from "services/auth-header";
import FORM_LABELS from "constants/formLabels2025";

const FIELDS_TO_KEEP = [
    "academicAchievements",
    "lastYearReportCardPhotoLink",
    "currentYearFirstSemesterReportCardPhotoLink",
    "sportsAndCreativeAchievements",
    "socialActivity",
    "schoolActivity",
    "motivation",
    "videoLink",
    "essay",
    "aboutFamily",
    "aboutApproachingVictory",
];

const filterFormLabels = (sourceObject, fieldsToKeep) => {
    return Object.keys(sourceObject)
        .filter((key) => fieldsToKeep.includes(key))
        .reduce((result, key) => {
            result[key] = sourceObject[key];
            return result;
        }, {});
};

const POINTS = [1, 2, 3];

const StageOneAssessment = ({ id, userRole, applicationAssessment }) => {
    const filteredLabels = filterFormLabels(FORM_LABELS, FIELDS_TO_KEEP);
    const navigate = useNavigate();

    const allowedEditorRoles = ["project manager", "director deputy"];
    const allowedViewerRoles = ["project manager", "director", "director deputy", "psychologist"];

    const values = applicationAssessment?.stageOneScores || {};
    const points = applicationAssessment?.stageOnePoints;
    const comment = applicationAssessment?.stageOneComment;
    const assessment = applicationAssessment?.stageOneAssessment;

    const [stageOneScores, setStageOneScores] = useState(
        Object.keys(filteredLabels).reduce((acc, key) => {
            acc[key] = values[key] || 1;
            return acc;
        }, {})
    );

    const handleChange = (field, value) => {
        setStageOneScores((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const [stageOnePoints, setStageOnePoints] = useState(Number.isInteger(points) ? points : "");
    const [stageOneComment, setStageOneComment] = useState(comment || "");
    const [stageOneAssessment, setStageOneAssessment] = useState(assessment || "");

    useEffect(() => {
        const sum = Object.values(stageOneScores).reduce((acc, val) => acc + (Number(val) || 0), 0);
        setStageOnePoints(sum);
    }, [stageOneScores]);

    const stageOneCommentHandler = (value) => {
        setStageOneComment(value);
    };

    const stageOneAssessmentHandler = (value) => {
        setStageOneAssessment(value);
    };

    const updateStageOne = () => {
        axios
            .post(
                API.assessment2025.updateStageOneAssessment,
                { id, stageOneScores, stageOnePoints, stageOneComment, stageOneAssessment },
                { headers: authHeader() }
            )
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    return (
        <div className="stage">
            <StageTitle title="Етап 1. Оцінювання заступником директора Школи" />

            {allowedViewerRoles.includes(userRole) &&
                (Number.isInteger(points) && assessment ? (
                    <div className="stage-rated" data-assessment={assessment}>
                        <p className="stage-rated-points">{points}</p>
                        <p className="stage-rated-comment">{comment}</p>
                        <p className="stage-rated-assessment">{assessment}</p>
                    </div>
                ) : (
                    <div className="stage-not-rated">
                        <p>Не оцінено</p>
                    </div>
                ))}

            {allowedEditorRoles.includes(userRole) && (
                <div className="stage-form">
                    {Object.keys(filteredLabels).map((field) => {
                        return (
                            <Select
                                label={filteredLabels[field]}
                                options={POINTS}
                                value={stageOneScores[field]}
                                handler={(option) => handleChange(field, option)}
                            />
                        );
                    })}

                    <Input label="Кількість балів (розраховується автоматично)" value={stageOnePoints} disabled={true} />
                    <Textarea label="Коментар" value={stageOneComment} handler={stageOneCommentHandler} />
                    <Select label="Фінальна оцінка" options={ASSESSMENTS} value={stageOneAssessment} handler={stageOneAssessmentHandler} />
                    <ButtonGroup>
                        <Button content="Зберегти" handler={updateStageOne} disabled={!(stageOnePoints && stageOneAssessment)} />
                    </ButtonGroup>
                </div>
            )}

            {!allowedViewerRoles.includes(userRole) && !allowedEditorRoles.includes(userRole) && (
                <div className="stage-rated" data-assessment={assessment}>
                    <p className="stage-rated-assessment">{assessment}</p>
                </div>
            )}
        </div>
    );
};

export default StageOneAssessment;
