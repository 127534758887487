import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducers from "../features/authSlice";
import notificationReducers from "../features/notificationSlice";

const rootReducer = combineReducers({
    auth: authReducers,
    notifications: notificationReducers,
});

export const store = configureStore({ reducer: rootReducer });
