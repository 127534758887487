import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

//API
import API from "app/api";

//services
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";

//components
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import SelectMultiple from "components/UI/SelectMultiple/SelectMultiple";
import Loader from "components/Loader/Loader";
import Select from "components/UI/Select/Select";
import PageMetadata from "components/PageMetadata/PageMetadata";
import PageTitle from "components/PageTitle/PageTitle";
import Pagination from "components/Pagination/Pagination";

//styles
import "./style.scss";

//icons
import TriangleDownIcon from "assets/icons/triangle-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";
import SearchInput from "components/UI/SearchInput/SearchInput";
import handleError from "api/handleError";

const PAGE_TITLE = "Анкети 2024 року";

const FUTURE_GRADE_OPTIONS = [8, 9, 10, 11];

const STAGES_OPTIONS = ["Всі анкети", "Етап 1", "Етап 2", "Етап 3", "Етап 4"];

const STAGES = {
    "Всі анкети": undefined,
    "Етап 1": 1,
    "Етап 2": 2,
    "Етап 3": 3,
    "Етап 4": 4,
};

const SLAGS = {
    рекомендовано: "accepted",
    "не рекомендовано": "rejected",
    "потенційно рекомендовано": "questionable",
};

const LIMIT_OPTIONS = [10, 25, 50, 100, 200];

const TableHeaderWithTip = ({ content, tip, sorting, sort, order, handler, colSpan = 1 }) => {
    return (
        <th className="table-header-with-tip" onClick={() => handler(sort)} colSpan={colSpan} data-col={sort}>
            <span className="table-header-with-tip-content">{content}</span>
            <span className="table-header-with-tip-explanation">
                ?<span>{tip}</span>
            </span>
            <span className="table-header-with-sorting-control">
                {sorting === sort && (
                    <span>
                        <img src={TriangleDownIcon} data-order={order} />
                    </span>
                )}
            </span>
        </th>
    );
};

const TableHeaderWithTipMultiple = ({ content, tip, sorting, sortOne, sortTwo, order, handler }) => {
    return (
        <th className="table-header-with-tip" colSpan={2}>
            <span className="table-header-with-tip-wrapper">
                <span className="table-header-divider">
                    <span className="table-header-with-tip-content">{content}</span>
                    <span className="table-header-with-tip-explanation">
                        ?<span>{tip}</span>
                    </span>
                </span>
                <span className="table-header-divider">
                    <span onClick={() => handler(sortOne)}>
                        Вчителі
                        <span className="table-header-with-sorting-control">
                            {sorting === sortOne && (
                                <span>
                                    <img src={TriangleDownIcon} data-order={order} />
                                </span>
                            )}
                        </span>
                    </span>
                    <span onClick={() => handler(sortTwo)}>
                        Психолог
                        <span className="table-header-with-sorting-control">
                            {sorting === sortTwo && (
                                <span>
                                    <img src={TriangleDownIcon} data-order={order} />
                                </span>
                            )}
                        </span>
                    </span>
                </span>
            </span>
        </th>
    );
};

const TableHeaderWithSorting = ({ content, sorting, sort, order, handler }) => {
    return (
        <th className="table-header-with-sorting" onClick={() => handler(sort)}>
            <span className="table-header-with-sorting-content">{content}</span>
            <span className="table-header-with-sorting-control">
                {sorting === sort && (
                    <span>
                        <img src={TriangleDownIcon} data-order={order} />
                    </span>
                )}
            </span>
        </th>
    );
};

const TableDataStageSingle = ({ assessment, points }) => {
    return (
        <td className="table-data-stage">
            <div className="table-data-stage-wrapper">
                <span className={`table-data-stage-${assessment ? SLAGS[assessment] : "default"}`} data-result={assessment}>
                    {(points || points === 0) && points}
                </span>
            </div>
        </td>
    );
};

const ApplicationsPage2024 = () => {
    const pageAnchor = useRef();

    const isAdmin = useSelector(selectAdminRole);

    const userProjectRole = AuthService.getCurrentUser().projectRoleName;
    const onlyStageTwoAllowedRoles = ["school teacher", "school psychologist"];
    const defaultStage = onlyStageTwoAllowedRoles.includes(userProjectRole) ? 2 : undefined;
    const defaultselectedStage = onlyStageTwoAllowedRoles.includes(userProjectRole) ? "Етап 2" : "Всі анкети";

    const navigate = useNavigate();
    const dispacth = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const [applications, setApplications] = useState([]);

    const [userId, setUserId] = useState(AuthService.getCurrentUser().id);
    const [stage, setStage] = useState(defaultStage);
    const [selectedStage, setSelectedStage] = useState(defaultselectedStage);

    const [grades, setGrades] = useState([...FUTURE_GRADE_OPTIONS]);
    const [selectedGrades, setSelectedGrades] = useState([]);

    const [name, setName] = useState(undefined);
    const [nameSearch, setNameSearch] = useState("");

    const [sorting, setSorting] = useState("id");
    const [order, setOrder] = useState("DESC");

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        axios
            .post(
                API.application2024.readAll,
                { sorting, order, selectedGrades, stage, name, limit, offset: (page - 1) * limit },
                { headers: authHeader() }
            )
            .then((response) => {
                setApplications(response.data?.rows);
                console.log(response.data?.rows);

                setPages(Array.from(Array(Math.ceil(response.data?.count / limit)).keys()));
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else {
                   handleError(error, dispacth, navigate);
                }
            });
    }, [sorting, order, selectedGrades, stage, name, page, limit]);

    const sortArray = (array) => {
        return array.sort((a, b) => a - b);
    };

    const gradesHandler = (value) => {
        if (grades.includes(value)) {
            let newGrades = [...grades];
            const index = newGrades.indexOf(value);
            newGrades.splice(index, 1);

            setGrades(sortArray(newGrades));
            setSelectedGrades((selectedGrades) => sortArray([...selectedGrades, value]));
        } else {
            const newSelectedGrades = [...selectedGrades];
            const index = newSelectedGrades.indexOf(value);
            newSelectedGrades.splice(index, 1);

            setSelectedGrades(sortArray(newSelectedGrades));
            setGrades((grades) => sortArray([...grades, value]));
        }
    };

    const sortingHandler = (value) => {
        if (sorting !== value) {
            setSorting(value);
        } else {
            setOrder((prev) => (prev === "ASC" ? "DESC" : "ASC"));
        }
        setPage(1);
    };

    const selectedStageHandler = (value) => {
        setSelectedStage(value);
        setStage(STAGES[value]);
    };

    const pageHandler = (value) => {
        pageAnchor.current.scrollIntoView();
        setPage(value);
    };

    const downloadApplications = () => {
        axios
            .post(
                API.application2024.exportApplications,
                {
                    sorting,
                    order,
                    selectedGrades,
                    stage,
                },
                { headers: authHeader(), responseType: "blob" }
            )
            .then((response) => {
                const date = new Date();
                const fileName = `file (${date.toLocaleString("uk")}).csv`;
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement("a");

                anchorElement.href = href;
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch((error) => {
                if (error.response.status && [401, 403].includes(error.response.status)) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    handleError(error, dispacth, navigate);
                }
            });
    };

    const limitHandler = (value) => {
        setLimit(value);
    };

    const nameSearchHandler = (value) => {
        setNameSearch(value);
    };

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            if (!nameSearch) {
                return setName(undefined);
            }
            setName(nameSearch);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [nameSearch]);

    return (
        <>
            <PageMetadata title={PAGE_TITLE} />

            <main className="container" ref={pageAnchor}>
                <div className="page applications-page">
                    <PageTitle pageTitle={PAGE_TITLE} />

                    <ButtonGroup>
                        <Button content="Анкети 2025 року" type="link" link={`/2025/applications`} />
                    </ButtonGroup>

                    <div className="applications-page-control">
                        <div>
                            <Select
                                label="Відобразити лише анкети, які пройшли"
                                options={STAGES_OPTIONS}
                                value={selectedStage}
                                handler={selectedStageHandler}
                                disabled={defaultStage}
                            />

                            <Select label="Результатів на сторінці" options={LIMIT_OPTIONS} value={limit} handler={limitHandler} />
                        </div>
                        <div>
                            <SelectMultiple
                                label="Майбутній клас апліканта"
                                options={grades}
                                selectedOptions={selectedGrades}
                                handler={gradesHandler}
                            />
                        </div>
                        <div>
                            <SearchInput label="Пошук анкет за ПІБ" type="text" value={nameSearch} handler={nameSearchHandler} />
                        </div>
                    </div>

                    {isAdmin && (
                        <ButtonGroup>
                            <Button content="Експортувати результати в csv" handler={downloadApplications} />
                        </ButtonGroup>
                    )}
                    <table className="applications-page-table">
                        <thead>
                            <tr>
                                <TableHeaderWithSorting content="ID" sorting={sorting} order={order} sort={"id"} handler={sortingHandler} />
                                <TableHeaderWithSorting
                                    content="ПІБ"
                                    sorting={sorting}
                                    order={order}
                                    sort={"name"}
                                    handler={sortingHandler}
                                />
                                <TableHeaderWithSorting
                                    content="Майбутній клас"
                                    sorting={sorting}
                                    order={order}
                                    sort={"futureGrade"}
                                    handler={sortingHandler}
                                />
                                <TableHeaderWithTip
                                    content="Етап 1"
                                    tip="Оцінювання Менеджером проєкту"
                                    sorting={sorting}
                                    order={order}
                                    sort={"stageOnePoints"}
                                    handler={sortingHandler}
                                />
                                <TableHeaderWithTip
                                    content="Етап 2"
                                    tip="Оцінювання Заступником директора Новопечерської школи"
                                    sorting={sorting}
                                    order={order}
                                    sort={"stageTwoPoints"}
                                    handler={sortingHandler}
                                />

                                <TableHeaderWithTipMultiple
                                    content="Етап 3"
                                    tip="Оцінювання Вчителями та Психологом Новопечерської школи"
                                    sorting={sorting}
                                    order={order}
                                    sortOne={"sum"}
                                    sortTwo={"stageThreePsychologistAssessment"}
                                    handler={sortingHandler}
                                    colSpan="2"
                                />
                                <TableHeaderWithTip
                                    content="Етап 4"
                                    tip="Оцінювання співбесіди Менеджером проєкту"
                                    sorting={sorting}
                                    order={order}
                                    sort={"stageFourPoints"}
                                    handler={sortingHandler}
                                />
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {applications.length > 0 ? (
                                applications.map((application) => {
                                    const { assessment } = application;
                                    return (
                                        <tr key={application.id}>
                                            <td>{application.id}</td>
                                            <td>{application.name}</td>
                                            <td>{application.futureGrade}</td>
                                            <TableDataStageSingle
                                                assessment={assessment?.stageOneAssessment}
                                                points={assessment?.stageOnePoints}
                                            />
                                            <TableDataStageSingle
                                                assessment={assessment?.stageTwoAssessment}
                                                points={assessment?.stageTwoPoints}
                                            />
                                            <TableDataStageSingle
                                                assessment={assessment?.stageThreeTeacherAssessment}
                                                points={assessment?.sum && assessment?.sum !== 0 && assessment?.sum / 5}
                                            />
                                            <TableDataStageSingle assessment={assessment?.stageThreePsychologistAssessment} />

                                            <TableDataStageSingle
                                                assessment={assessment?.stageFourAssessment}
                                                points={assessment?.stageFourPoints}
                                            />

                                            <td>
                                                <Button
                                                    content="Відкрити"
                                                    type="link"
                                                    link={`/2024/application/${application.id}`}
                                                    highlighted={false}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={9}>Анкет ще не отримано або анкети відсутні за вашим критерієм пошуку.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pagination page={page} pages={pages} handler={pageHandler} />
                </div>
            </main>
            {isLoading && <Loader />}
        </>
    );
};

export default ApplicationsPage2024;
