import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

//components
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import Checkbox from "../UI/Checkbox/Checkbox";
import Textarea from "../UI/Textarea/Textarea";
import InputDate from "../UI/InputDate/InputDate";
import ButtonGroup from "../UI/ButtonGroup/ButtonGroup";
import Button from "../UI/client/Button/Button";

//styles
import "./style.scss";

//api
import API from "../../app/api";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../UI/Modal/Modal";

//constants
import FORM_LABELS from "constants/formLabels2025";
import handleError from "api/handleError";

const INFORMATION_SOURCE_OPTIONS = ["Facebook", "сайт Новопечерської школи", "Освіторія.медіа", "YouTube", "Telegram", "Instagram", "Інше"];
const FUTURE_GRADE_OPTIONS = [8, 9, 10, 11];

const FormNone = ({ content }) => {
    return <div className="form-note">{content}</div>;
};

const ApplicationForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isApplicationValid, setIsApplicationValid] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [name, setName] = useState("");
    const [birthdayDate, setbirthdayDate] = useState(new Date(2009, 0, 1, 0, 0, 0));
    const [informationSource, setInformationSource] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [emailValidationMsg, setEmailValidationMsg] = useState(null);

    const [futureGrade, setFutureGrade] = useState("");
    const [educationalInstitution, setEducationalInstitution] = useState("");
    const [settlement, setSettlement] = useState("");

    const [socialMediaLink, setSocialMediaLink] = useState("");
    const [photoLink, setPhotoLink] = useState("");

    const [academicAchievements, setAcademicAchievements] = useState("");
    const [lastYearReportCardPhotoLink, setLastYearReportCardPhotoLink] = useState("");
    const [currentYearFirstSemesterReportCardPhotoLink, setCurrentYearFirstSemesterReportCardPhotoLink] = useState("");

    const [sportsAndCreativeAchievements, setSportsAndCreativeAchievements] = useState("");
    const [socialActivity, setSocialActivity] = useState("");
    const [schoolActivity, setSchoolActivity] = useState("");
    const [motivation, setMotivation] = useState("");
    const [videoLink, setVideoLink] = useState("");
    const [aboutFamily, setAboutFamily] = useState("");
    const [aboutApproachingVictory, setAboutApproachingVictory] = useState("");
    const [contactPersonName, setContactPersonName] = useState("");
    const [contactPersonEmail, setContactPersonEmail] = useState("");
    const [contactPersonPhone, setContactPersonPhone] = useState("");
    const [parentsSocialMediaLink, setParentsSocialMediaLink] = useState("");
    const [essay, setEssay] = useState("");

    const [isConfirmed, setIsConfirmed] = useState(true);
    const [isAllowedDataProcessing, setIsAllowedDataProcessing] = useState(true);
    const [isAcceptedTerms, setIsAcceptedTerms] = useState(true);

    const nameHandler = (value) => {
        setName(value);
    };

    const birthdayDateHandler = (value) => {
        setbirthdayDate(value);
    };

    const phoneNumberHandler = (value) => {
        setPhoneNumber(value);
    };

    const emailHandler = (value) => {
        setEmail(value);
        emailValidationHandler(value, "input");
    };

    const emailFocusHandler = (value) => {
        setEmailValidationMsg(null);
    };

    const emailBlurHandler = (value) => {
        emailValidationHandler(value, "blur");
    };

    const emailValidationHandler = (value, type) => {
        const reg = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
            "i"
        );
        if (!reg.test(value)) {
            setEmailValidation(false);
            type !== "input" && setEmailValidationMsg("Вказано некоректний email.");
        } else {
            setEmailValidation(true);
            setEmailValidationMsg(null);
        }
    };

    const InformationSourceHandler = (value) => {
        setInformationSource(value);
    };

    const futureGradeHandler = (value) => {
        setFutureGrade(value);
    };

    const educationalInstitutionHandler = (value) => {
        setEducationalInstitution(value);
    };

    const settlementHandler = (value) => {
        setSettlement(value);
    };

    const socialMediaLinkHandler = (value) => {
        setSocialMediaLink(value);
    };

    const photoLinkHandler = (value) => {
        setPhotoLink(value);
    };

    const academicAchievementsHandler = (value) => {
        setAcademicAchievements(value);
    };

    const lastYearReportCardPhotoLinkHandler = (value) => {
        setLastYearReportCardPhotoLink(value);
    };

    const currentYearFirstSemesterReportCardPhotoLinkHandler = (value) => {
        setCurrentYearFirstSemesterReportCardPhotoLink(value);
    };

    const sportsAndCreativeAchievementsHandler = (value) => {
        setSportsAndCreativeAchievements(value);
    };

    const socialActivityHandler = (value) => {
        setSocialActivity(value);
    };

    const schoolActivityHandler = (value) => {
        setSchoolActivity(value);
    };

    const motivationHandler = (value) => {
        setMotivation(value);
    };

    const videoLinkHandler = (value) => {
        setVideoLink(value);
    };

    const aboutFamilyHandler = (value) => {
        setAboutFamily(value);
    };

    const aboutApproachingVictoryHandler = (value) => {
        setAboutApproachingVictory(value);
    };

    const contactPersonNameHandler = (value) => {
        setContactPersonName(value);
    };

    const contactPersonEmailHandler = (value) => {
        setContactPersonEmail(value);
    };

    const contactPersonPhoneHandler = (value) => {
        setContactPersonPhone(value);
    };

    const parentsSocialMediaLinkHandler = (value) => {
        setParentsSocialMediaLink(value);
    };

    const essayHandler = (value) => {
        setEssay(value);
    };

    const isConfirmedHandler = () => {
        setIsConfirmed((prevValue) => !prevValue);
    };

    const isAllowedDataProcessingHandler = () => {
        setIsAllowedDataProcessing((prevValue) => !prevValue);
    };

    const isAcceptedTermsHandler = () => {
        setIsAcceptedTerms((prevValue) => !prevValue);
    };

    useEffect(() => {
        if (
            name &&
            birthdayDate &&
            informationSource &&
            phoneNumber &&
            email &&
            emailValidation &&
            futureGrade &&
            educationalInstitution &&
            settlement &&
            socialMediaLink &&
            photoLink &&
            academicAchievements &&
            lastYearReportCardPhotoLink &&
            currentYearFirstSemesterReportCardPhotoLink &&
            sportsAndCreativeAchievements &&
            socialActivity &&
            schoolActivity &&
            motivation &&
            videoLink &&
            aboutFamily &&
            aboutApproachingVictory &&
            contactPersonName &&
            contactPersonEmail &&
            contactPersonPhone &&
            parentsSocialMediaLink &&
            essay &&
            isConfirmed &&
            isAllowedDataProcessing &&
            isAcceptedTerms &&
            isCaptchaValid
        ) {
            setIsApplicationValid(true);
        } else {
            setIsApplicationValid(false);
        }
    }, [
        name,
        birthdayDate,
        informationSource,
        phoneNumber,
        email,
        emailValidation,
        futureGrade,
        educationalInstitution,
        settlement,
        socialMediaLink,
        photoLink,
        academicAchievements,
        lastYearReportCardPhotoLink,
        currentYearFirstSemesterReportCardPhotoLink,
        sportsAndCreativeAchievements,
        socialActivity,
        schoolActivity,
        motivation,
        videoLink,
        aboutFamily,
        aboutApproachingVictory,
        contactPersonName,
        contactPersonEmail,
        contactPersonPhone,
        parentsSocialMediaLink,
        essay,
        isConfirmed,
        isAllowedDataProcessing,
        isAcceptedTerms,
        isCaptchaValid,
    ]);

    const sendApplication = () => {
        axios
            .post(API.application2025.create, {
                name,
                birthdayDate,
                informationSource,
                phoneNumber,
                email,
                futureGrade,
                educationalInstitution,
                settlement,
                socialMediaLink,
                photoLink,
                academicAchievements,
                lastYearReportCardPhotoLink,
                currentYearFirstSemesterReportCardPhotoLink,
                sportsAndCreativeAchievements,
                socialActivity,
                schoolActivity,
                motivation,
                videoLink,
                aboutFamily,
                aboutApproachingVictory,
                contactPersonName,
                contactPersonEmail,
                contactPersonPhone,
                parentsSocialMediaLink,
                essay,
                isConfirmed,
                isAllowedDataProcessing,
                isAcceptedTerms,
            })
            .then(() => {
                setIsModalOpen(true);
            })
            .catch((error) => {
                handleError(error, dispatch, navigate);
            });
    };

    const onChangeCaptcha = (value) => {
        if (value) {
            return setIsCaptchaValid(true);
        }

        return setIsCaptchaValid(false);
    };

    const closeModalHandler = () => {
        navigate("/");
        navigate(0);
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div id="application" className="applicant-form">
                <h2 className="applicant-form-title">Подання заявки</h2>

                <FormNone content="Важливо! Учні Новопечерської школи не можуть подаватися на програму." />

                <Input label={FORM_LABELS.name} value={name} handler={nameHandler} required={true} maxLength={200} />

                <InputDate label={FORM_LABELS.birthdayDate} value={birthdayDate} handler={birthdayDateHandler} required={true} />

                <Select
                    label={FORM_LABELS.informationSource}
                    options={INFORMATION_SOURCE_OPTIONS}
                    value={informationSource}
                    handler={InformationSourceHandler}
                    required={true}
                />

                <Input label={FORM_LABELS.phoneNumber} value={phoneNumber} handler={phoneNumberHandler} required={true} maxLength={200} />
                <Input
                    label={FORM_LABELS.email}
                    value={email}
                    handler={emailHandler}
                    focusHandler={emailFocusHandler}
                    blurHandler={emailBlurHandler}
                    validationMsg={emailValidationMsg}
                    required={true}
                    maxLength={200}
                />

                <Select
                    label={FORM_LABELS.futureGrade}
                    options={FUTURE_GRADE_OPTIONS}
                    value={futureGrade}
                    handler={futureGradeHandler}
                    required={true}
                />

                <Input
                    label={FORM_LABELS.educationalInstitution}
                    value={educationalInstitution}
                    handler={educationalInstitutionHandler}
                    required={true}
                    maxLength={200}
                />

                <Input label={FORM_LABELS.settlement} value={settlement} handler={settlementHandler} required={true} maxLength={200} />

                <Input
                    label={FORM_LABELS.socialMediaLink}
                    value={socialMediaLink}
                    handler={socialMediaLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Input label={FORM_LABELS.photoLink} value={photoLink} handler={photoLinkHandler} required={true} maxLength={600} />

                <FormNone content="Розкажіть про себе. Не соромтесь і розповідайте про свої здобутки. Нам важливо зрозуміти, чим саме Ви цікавитесь та яких важливих результатів досягли в кожній сфері." />

                <Textarea
                    label={FORM_LABELS.academicAchievements}
                    value={academicAchievements}
                    handler={academicAchievementsHandler}
                    maxLength={3000}
                />

                <Input
                    label={FORM_LABELS.lastYearReportCardPhotoLink}
                    value={lastYearReportCardPhotoLink}
                    handler={lastYearReportCardPhotoLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Input
                    label={FORM_LABELS.currentYearFirstSemesterReportCardPhotoLink}
                    value={currentYearFirstSemesterReportCardPhotoLink}
                    handler={currentYearFirstSemesterReportCardPhotoLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <Textarea
                    label={FORM_LABELS.sportsAndCreativeAchievements}
                    value={sportsAndCreativeAchievements}
                    handler={sportsAndCreativeAchievementsHandler}
                    maxLength={3000}
                />

                <Textarea label={FORM_LABELS.socialActivity} value={socialActivity} handler={socialActivityHandler} maxLength={3000} />

                <Textarea label={FORM_LABELS.schoolActivity} value={schoolActivity} handler={schoolActivityHandler} maxLength={3000} />

                <Textarea label={FORM_LABELS.motivation} value={motivation} handler={motivationHandler} required={true} maxLength={3000} />

                <FormNone content="Нас також цікавлять Ваші досягнення та захоплення. Інколи їх складно передати словами, тому Ви можете підготувати коротке відео про себе. Розкажіть, хто Ви, чим захоплюєтеся, як проводите вільний час, про що мрієте. Тривалість – від 1 до 2 хв. Будь ласка, дотримуйтеся цього часового проміжку. Відео залийте на YouTube чи інший простір, де можна буде його переглянути за наданим доступним посиланням. Пам'ятайте про безпеку в інтернеті!" />

                <Input label={FORM_LABELS.videoLink} value={videoLink} handler={videoLinkHandler} required={true} maxLength={600} />

                <Textarea label={FORM_LABELS.essay} value={essay} handler={essayHandler} maxLength={3000} height="big" />

                <FormNone content="Коли ми приймаємо учня/ученицю до школи, ми також приймаємо й родину до великої спільноти Новопечерської школи. Разом ми виховуємо лідерів, майбутніх змінотворців України. Будемо раді дізнатися трохи більше про Ваших рідних та про Ваші лідерські якості." />

                <Textarea label={FORM_LABELS.aboutFamily} value={aboutFamily} handler={aboutFamilyHandler} maxLength={3000} />

                <Textarea
                    label={FORM_LABELS.aboutApproachingVictory}
                    value={aboutApproachingVictory}
                    handler={aboutApproachingVictoryHandler}
                    maxLength={3000}
                />

                <Input
                    label={FORM_LABELS.contactPersonName}
                    value={contactPersonName}
                    handler={contactPersonNameHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FORM_LABELS.contactPersonEmail}
                    value={contactPersonEmail}
                    handler={contactPersonEmailHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FORM_LABELS.contactPersonPhone}
                    value={contactPersonPhone}
                    handler={contactPersonPhoneHandler}
                    required={true}
                    maxLength={200}
                />

                <Input
                    label={FORM_LABELS.parentsSocialMediaLink}
                    value={parentsSocialMediaLink}
                    handler={parentsSocialMediaLinkHandler}
                    required={true}
                    maxLength={600}
                />

                <FormNone content="Ми гарантуємо конфіденційність усієї наданої інформації." />

                <Checkbox label={FORM_LABELS.isConfirmed} value={isConfirmed} handler={isConfirmedHandler} />

                <Checkbox
                    label={FORM_LABELS.isAllowedDataProcessing}
                    value={isAllowedDataProcessing}
                    handler={isAllowedDataProcessingHandler}
                />

                <Checkbox label={FORM_LABELS.isAcceptedTerms} value={isAcceptedTerms} handler={isAcceptedTermsHandler} />

                <ButtonGroup position="center">
                    <ReCAPTCHA sitekey="6LecyHspAAAAAHssfwag1_07ojfXDUEnp3uQvRWV" onChange={onChangeCaptcha} />
                </ButtonGroup>

                <ButtonGroup>
                    <Button content="Надіслати" handler={sendApplication} disabled={!isApplicationValid} />
                </ButtonGroup>
            </div>
            {isModalOpen && (
                <Modal
                    title="Дякуємо!"
                    message="Ваша заявка отримана"
                    handler={closeModalHandler}
                    buttonText="Закрити"
                    closeHandler={closeModalHandler}
                />
            )}
        </>
    );
};

export default ApplicationForm;
