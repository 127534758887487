import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//styles
import "./style.scss";

//images
import adminLogo from "assets/img/logos/main-logo.png";
import mainLogoWhite from "assets/img/logos/main-logo-white.png";

//icons
import menuIcon from "assets/icons/menu-burger-white.svg";

//constants
const NOVOSHKOLA_URL = "https://novoshkola.com.ua";

const ABOUT_SCHOOL_DROPDOWN_ITEMS = [
    {
        title: "Про Новопечерську школу",
        url: `${NOVOSHKOLA_URL}/philosophy`,
    },
    {
        title: "Лідер у мені",
        url: `${NOVOSHKOLA_URL}/7_habits`,
    },
    {
        title: "Навички ХХІ століття",
        url: `${NOVOSHKOLA_URL}/21st_century_skills`,
    },
    {
        title: "Інфраструктура Школи",
        url: `${NOVOSHKOLA_URL}/infrastructure`,
    },
    {
        title: "Команда",
        url: `${NOVOSHKOLA_URL}/team`,
    },
    {
        title: "Гуртки",
        url: `${NOVOSHKOLA_URL}/electives`,
    },
    {
        title: "Канадський диплом",
        url: `${NOVOSHKOLA_URL}/blended-program`,
    },
    {
        title: "Річний огляд 2018/2019",
        url: `${NOVOSHKOLA_URL}/annual-school-review-2019`,
    },
    {
        title: "Річний огляд 2019/2020",
        url: `${NOVOSHKOLA_URL}/annual-school-review-2020`,
    },
    {
        title: "Річний огляд 2020/2021",
        url: `${NOVOSHKOLA_URL}/annual-school-review-2021`,
    },
    {
        title: "Річний огляд 2022/2023",
        url: `${NOVOSHKOLA_URL}/annual-school-review-2022`,
    },
];

const ENROLMENT_DROPDOWN_ITEMS = [
    {
        title: "Дошкільна підготовка",
        url: `${NOVOSHKOLA_URL}/doshkilna_pidgotovka`,
    },
    {
        title: "Початкова школа (1-4 класи)",
        url: `${NOVOSHKOLA_URL}/primary_school`,
    },
    {
        title: "Середня та старша школа (5-11 класи)",
        url: `${NOVOSHKOLA_URL}/secondary-high-school`,
    },
    {
        title: "Стипендіальна програма",
        url: "https://novo.scholarship.osvitoria.org",
    },
    {
        title: "Процес вступу",
        url: `${NOVOSHKOLA_URL}/enrolment`,
    },
    {
        title: "Вартість навчання",
        url: `${NOVOSHKOLA_URL}/cost`,
    },
    {
        title: "Заповнити анкету на вступ",
        url: "https://novoshkola.microsoftcrmportals.com/contact-us",
    },
    {
        title: "Вступ на українсько-канадську програму",
        url: `${NOVOSHKOLA_URL}/blended-program`,
    },
];

const FOR_PARENTS_DROPDOWN_ITEMS = [
    {
        title: "Оплата онлайн",
        url: `${NOVOSHKOLA_URL}/payments`,
    },
    {
        title: "Довідник",
        url: `${NOVOSHKOLA_URL}/handbook`,
    },
    {
        title: "Договір приєднання",
        url: `${NOVOSHKOLA_URL}/dogovir23`,
    },
];

export default function Header() {
    const location = useLocation();

    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const mobileNavHandler = () => {
        setIsMobileNavOpen((prevState) => !prevState);
    };

    useEffect(() => {
        const handleBodyScroll = () => {
            if (isMobileNavOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
            }
        };

        handleBodyScroll();

        return () => {
            document.body.style.overflow = "";
        };
    }, [isMobileNavOpen]);

    return (
        <header className="header">
            <div className="container">
                <div className="header-wrapper">
                    <div className="header-logo">
                        <a href={NOVOSHKOLA_URL} target="_blank" rel="noreferrer">
                            <img className="header-logo-image" src={adminLogo} alt="admin-logo" />
                            <img className="header-logo-image-white" src={mainLogoWhite} alt="admin-logo" />
                        </a>
                    </div>

                    <div className="header-nav-external">
                        <div className="header-nav-external-dropdown">
                            <a href={`${NOVOSHKOLA_URL}/philosophy`} target="_blank" rel="noreferrer">
                                Про Школу
                            </a>
                            <div className="header-nav-external-dropdown-menu">
                                {ABOUT_SCHOOL_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="header-nav-external-dropdown">
                            <a href={`${NOVOSHKOLA_URL}/enrolment`} target="_blank" rel="noreferrer">
                                Вступ
                            </a>
                            <div className="header-nav-external-dropdown-menu">
                                {ENROLMENT_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <a href={`${NOVOSHKOLA_URL}/news`} target="_blank" rel="noreferrer">
                            Новини
                        </a>

                        <div className="header-nav-external-dropdown">
                            <a href={`${NOVOSHKOLA_URL}/#`} target="_blank" rel="noreferrer">
                                Для батьків
                            </a>
                            <div className="header-nav-external-dropdown-menu">
                                {FOR_PARENTS_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>

                        <a href="https://online.novoshkola.com.ua" target="_blank" rel="noreferrer">
                            Дистанційна освіта
                        </a>
                    </div>

                    <div className="header-nav-internal">
                        {location && location?.pathname === "/" ? (
                            <Link to="/check-applications">Перевірити отримання</Link>
                        ) : (
                            <Link to="/">Заповнити анкету</Link>
                        )}
                    </div>

                    <div className="header-mobile-control">
                        <button onClick={mobileNavHandler}>
                            <img src={menuIcon} alt="menu icon" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mobile-nav" data-open={isMobileNavOpen}>
                <div className="mobile-nav-wrapper">
                    <div className="mobile-nav-control">
                        <button onClick={mobileNavHandler}>x</button>
                    </div>

                    <div className="mobile-nav-external">
                        <div className="mobile-nav-external-dropdown">
                            <a className="has-dropdown-menu" href={`${NOVOSHKOLA_URL}/philosophy`} target="_blank" rel="noreferrer">
                                Про Школу
                            </a>
                            <div className="mobile-nav-external-dropdown-menu">
                                {ABOUT_SCHOOL_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mobile-nav-external-dropdown">
                            <a className="has-dropdown-menu" href={`${NOVOSHKOLA_URL}/enrolment`} target="_blank" rel="noreferrer">
                                Вступ
                            </a>
                            <div className="mobile-nav-external-dropdown-menu">
                                {ENROLMENT_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                        <a href={`${NOVOSHKOLA_URL}/news`} target="_blank" rel="noreferrer">
                            Новини
                        </a>

                        <div className="mobile-nav-external-dropdown">
                            <a className="has-dropdown-menu" href={`${NOVOSHKOLA_URL}/#`} target="_blank" rel="noreferrer">
                                Для батьків
                            </a>
                            <div className="mobile-nav-external-dropdown-menu">
                                {FOR_PARENTS_DROPDOWN_ITEMS.map((item, index) => {
                                    const { title, url } = item;
                                    return (
                                        <a key={index} href={url} target="_blank" rel="noreferrer">
                                            {title}
                                        </a>
                                    );
                                })}
                            </div>
                        </div>

                        {/* <a href="https://online.novoshkola.com.ua" target="_blank" rel="noreferrer">
                            Дистанційна освіта
                        </a>

                        <a href={`${NOVOSHKOLA_URL}/philosophy`} target="_blank" rel="noreferrer">
                            Про Школу
                        </a>
                        <a href={`${NOVOSHKOLA_URL}/enrolment`} target="_blank" rel="noreferrer">
                            Вступ
                        </a>
                        <a href={`${NOVOSHKOLA_URL}/news`} target="_blank" rel="noreferrer">
                            Новини
                        </a> */}
                    </div>
                    <div className="mobile-nav-internal">
                        {location && location?.pathname === "/" ? (
                            <Link to="/check-applications" onClick={mobileNavHandler}>
                                Перевірка анкети
                            </Link>
                        ) : (
                            <Link to="/" onClick={mobileNavHandler}>
                                Заповнити анкету
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
}
