const FORM_LABELS = {
    name: "Зазначте, будь ласка, своє прізвище, ім’я та по батькові.",
    birthdayDate: "Зазначте, будь ласка, свою дату народження.",
    informationSource: "Звідки Ви дізнались про Стипендіальну програму?",
    phoneNumber: "Зазначте, де і як з Вами можна швидко сконтактувати (наприклад, Viber +380502222222  чи Telegram @stypendiat).",
    email: "Зазначте, будь ласка, Вашу електронну пошту.",
    futureGrade: "У якому класі Ви будете навчатися в наступного 2025/2026 навчального року?",
    educationalInstitution: "У якому закладі Ви нині навчаєтеся? (Зазначте повну офіційну назву.)",
    settlement: "З якого Ви міста/села та області?",
    socialMediaLink: "Надайте, будь ласка, посилання на Ваш профіль у соціальних мережах (Facebook, Instagram, TikTok тощо).",
    photoLink: "Надайте посилання на Ваше фото (попередньо перевірте, будь ласка, налаштування доступу до файлу).",
    academicAchievements:
        "Якщо Ви переможець/переможниця предметних олімпіад, конкурсів МАН, маєте публікації або винаходи тощо, зазначте, будь ласка.",
    lastYearReportCardPhotoLink:
        "Надайте посилання на фото Вашого табеля минулого навчального року (попередньо перевірте, будь ласка, налаштування доступу до файлу).",
    currentYearFirstSemesterReportCardPhotoLink:
        "Надайте посилання на фото Вашого табеля за І семестр цього навчального року (попередньо перевірте, будь ласка, налаштування доступу до файлу).",
    sportsAndCreativeAchievements:
        "Якщо Ви професійно займаєтесь певним видом спорту або маєте кваліфікацію в певному виді творчості, розкажіть про це, будь ласка. Поділіться своїми творчими та/або спортивними досягненнями, якими Ви пишаєтеся найбільше.",
    socialActivity: "Опишіть свій досвід участі в соціальних/волонтерських проєктах чи благодійних акціях.",
    schoolActivity:
        "Розкажіть про свою залученість до різних проєктів і заходів у закладі, де нині навчаєтеся. Які лідерські ролі доводилося Вам виконувати в школі?",
    motivation: "Чому Ви хочете отримати грант на навчання саме в Новопечерській школі?",
    videoLink: "Надайте посилання на відео.",
    essay: "У вільній формі опишіть, будь ласка, Ваш досвід командної роботи та розкрийте важливість об’єднання зусиль для досягнення кращих результатів.",
    aboutFamily: "Розкажіть про Вашу сім'ю (як ви проводите час разом, чи маєте особливі сімейні традиції тощо).",
    aboutApproachingVictory: "Що Ви спільно з родиною робите для наближення нашої Перемоги?",
    contactPersonName: "Зазначте ПІБ батьків або контактної особи.",
    contactPersonEmail: "Зазначте електронну пошту батьків або контактної особи.",
    contactPersonPhone: "Зазначте номер телефону батьків або контактної особи.",
    parentsSocialMediaLink: "Поділіться, будь ласка, посиланнями на профілі батьків або контактної особи в соціальних мережах.",
    isConfirmed: "Я підтверджую свою фізичну присутність на етапі проходження конкурсних випробувань.",
    isAllowedDataProcessing: "Я даю згоду на обробку персональних даних.",
    isAcceptedTerms:
        "Я усвідомлюю, що Стипендіальна програма – це соціальний проєкт для здібних дітей з родин із рівнем достатку низьким або нижче середнього, а також підтримка родинам, що опинилися в складних життєвих обставинах унаслідок повномасштабного воєнного вторгнення. Ви підтверджуєте, що Ваша родина відповідає одному з критеріїв і готові надати (за необхідності) підтверджувальні документи.",
};

export default FORM_LABELS;
