import { useEffect } from "react";

// states
import { useDispatch, useSelector } from "react-redux";
import { removeNotification, selectNotifications } from "features/notificationSlice";

// styles
import styles from "./styles.module.scss";

const Notification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    // const notifications = [
    //     { id: 1, message: "Тестове сповіщення помилки.", type: "error" },
    //     { id: 2, message: "Тестове сповіщення успіху.", type: "success" },
    // ];

    useEffect(() => {
        const timers = notifications.map((notification) => {
            const { id } = notification;
            return setTimeout(() => {
                dispatch(removeNotification(id));
            }, 3000);
        });

        return () => {
            timers.forEach((timer) => clearTimeout(timer));
        };
    }, [notifications, dispatch]);

    const closeHandler = (id) => {
        dispatch(removeNotification(id));
    };

    return (
        <div className={styles.notificationContainer}>
            {notifications.map((notification, index) => {
                const { id, message, type = "error" } = notification;
                return (
                    <div key={index} className={styles.notification} data-type={type}>
                        {message}
                        <button className={styles.notification__button} onClick={() => closeHandler(id)}>
                            &#10006;
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default Notification;
