import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//components
import StageTitle from "./StageTitle";
import Button from "../../UI/Button/Button";
import ButtonGroup from "../../UI/ButtonGroup/ButtonGroup";
import Input from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";

//constants
import ASSESSMENTS from "../../../constants/applicationAssessments";
import API from "../../../app/api";
import AuthService from "../../../services/auth.service";
import authHeader from "../../../services/auth-header";

const StageOneAssessment = ({ id, userRole, applicationAssessment }) => {
    const navigate = useNavigate();

    const allowedEditorRoles = ["project manager"];
    const allowedViewerRoles = [
        "project manager",
        "project director",
        "school director",
        "school director deputy",
        "school teacher",
        "school psychologist",
        "school manager",
    ];

    const points = applicationAssessment?.stageOnePoints;
    const assessment = applicationAssessment?.stageOneAssessment;

    const [stageOnePoints, setStageOnePoints] = useState(Number.isInteger(points) ? points : "");
    const [stageOneAssessment, setStageOneAssessment] = useState(assessment || "");

    const stageOnePointsHandler = (value) => {
        const onlyNumbersRegExp = new RegExp("^[0-9]+$");

        if (onlyNumbersRegExp.test(value)) {
            setStageOnePoints(value);
        } else if (!value) {
            setStageOnePoints("");
        }
    };

    const stageOneAssessmentHandler = (value) => {
        setStageOneAssessment(value);
    };

    // const updateStageOne = () => {
    //     axios
    //         .post(API.assessment.updateStageOneAssessment, { id, stageOnePoints, stageOneAssessment }, { headers: authHeader() })
    //         .then(() => {
    //             window.location.reload();
    //         })
    //         .catch((error) => {
    //             if (error.response.status && [401, 403].includes(error.response.status)) {
    //                 AuthService.logout();
    //                 navigate("/signin");
    //                 window.location.reload();
    //             } else {
    //                 navigate("/error", { state: { error: error.response.data.message } });
    //             }
    //         });
    // };

    return (
        <div className="stage">
            <StageTitle title="Етап 1. Попереднє оцінювання" />

            {allowedViewerRoles.includes(userRole) &&
                (Number.isInteger(points) && assessment ? (
                    <div className="stage-rated" data-assessment={assessment}>
                        <p className="stage-rated-points">{points}</p>
                        <p className="stage-rated-assessment">{assessment}</p>
                    </div>
                ) : (
                    <div className="stage-not-rated">
                        <p>Не оцінено</p>
                    </div>
                ))}

            {allowedEditorRoles.includes(userRole) && (
                <div className="stage-form">
                    <Input label="Кількість балів" value={stageOnePoints} handler={stageOnePointsHandler} disabled={true} />
                    <Select
                        label="Фінальна оцінка"
                        options={ASSESSMENTS}
                        value={stageOneAssessment}
                        handler={stageOneAssessmentHandler}
                        disabled={true}
                    />

                    {/* <ButtonGroup>
                        <Button content='Зберегти' handler={updateStageOne} disabled={!(stageOnePoints && stageOneAssessment)} />
                    </ButtonGroup> */}
                </div>
            )}

            {!allowedViewerRoles.includes(userRole) && !allowedEditorRoles.includes(userRole) && (
                <p className="stage-note">У вас немає прав на перегляд оцінювання цього етапу.</p>
            )}
        </div>
    );
};

export default StageOneAssessment;
